import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Wrl_10mm = () => {
  const location = useLocation();
  const { image } = location.state || {
    image: { url: "", text: "Default", name: "Unknown", description: "No description available" },
  };
  const [currentImage, setCurrentImage] = useState(image.url || "");

  // Safely access image

  if (!image) {
    return (
      <p>
        Product data is not available. Please navigate from the Laminates page.
      </p>
    );
  }

  const { name, description, text } = image;

  const colorOptions = {
    "Krono Infinity Range": [
      {
        name: "Horizon Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/infinity/new-product-details/D-4591-Dab-Horizon-1_decor_big__FocusFillWzU2MCwzOTIsIngiLDc5XQ.jpg",
      },
      {
        name: "Space Oak ",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/infinity/new-product-details/D-4594-Dab-Space-1_decor_big__FocusFillWzU2MCwzOTIsIngiLDc5XQ.jpg",
      },
      {
        name: "Galaxy Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/infinity/galaxy-oak/4592_perspektywa_zamknieta_format_A4_big_RGB__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Moon Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/infinity/moon-oak/3728_perspektywa_zamknieta_format_A4_big_RGB__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Night Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/infinity/night-oak/4595_perspektywa_zamknieta_format_A4_big_RGB__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Sun Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/infinity/sun-oak/4593_perspektywa_zamknieta_format_A4_big_RGB__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      }
    ],
  };
    
  
  const filteredColorOptions = colorOptions[text] || [];  

  const handleColorClick = (color) => {
    setCurrentImage(color.imageUrl);
  };
  return (
    <div className="product-detail">
      <Link to="/10mm_wrl" className="back-link">
        Back
      </Link>
      <div className="product-main">
        <img className="main-product-image" src={currentImage} alt={name} />
        <div className="product-info">
          <h2>{name}</h2>
          <strong>{description}</strong>
          <h5>Category: {text}</h5>

          <div className="colors">
          {filteredColorOptions.length > 0 ? (
              filteredColorOptions.map((color, idx) => (
              <div
                key={idx}
                className="color-swatch"
                onClick={() => handleColorClick(color)}
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  margin: "2px",
                }}
              >
                <img
                  src={color.imageUrl}
                  alt={`Color Option ${idx + 1}`}
                  style={{
                    width: "70px",
                    height: "70px",
                    border: "2px solid #ddd",
                  }}
                />
                <h6>{color.name}</h6>
              </div>
            ))
          ) : (
            <p>No colors available for this category.</p>
          )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wrl_10mm;
