import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Wrl_8mm = () => {
  const location = useLocation();
  const { image } = location.state || {};

  const [currentImage, setCurrentImage] = useState(image.url || "");

  // Safely access image

  if (!image) {
    return (
      <p>
        Product data is not available. Please navigate from the Laminates page.
      </p>
    );
  }

  const {  name, text } = image;

  const colorOptions = {
    "krono Enigma": [
      {
        name: "Charlotte Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/enigma/new-product-details/3941_floor__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Cora Oak ",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/enigma/new-product-details/3340_floor__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Kelly Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/enigma/new-product-details/3709_floor__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Madison Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/enigma/new-product-details/3308_floor__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Rose Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/enigma/new-product-details/4531_floor__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Taylor Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/enigma/new-product-details/4911_floor__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      }
    ],
    "krono Movie": [
      {
        name: "Charlie Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/movie/new-product-details/4579_floor__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Fantasy Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/movie/new-product-details/4584_floor__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Festival Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/movie/new-product-details/40234_floor__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Hollywood Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/movie/new-product-details/4581_floor__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Musical Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/movie/new-product-details/40154_floor__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Oscar Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/movie/new-product-details/4582_floor__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Western Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/movie/new-product-details/4580_floor__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Cannes Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/movie/cannes-oak/Cannes_4583_panel__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },

    ],
   
  
};
  
  const filteredColorOptions = colorOptions[text] || [];

  const handleColorClick = (color) => {
    setCurrentImage(color.imageUrl);
  };

  return (
    <div className="product-detail">
      <Link to="/8mm_wrl" className="back-link">
        Back
      </Link>
      <div className="product-main">
        <img className="main-product-image" src={currentImage} alt={name} />
        <div className="product-info">
          <h2>{name}</h2>
          {/* <strong>{description}</strong> */}
          <h5>Category: {text}</h5>

          <div className="colors">
            {filteredColorOptions.length > 0 ? (
              filteredColorOptions.map((color, idx) => (
                <div
                  key={idx}
                  className="color-swatch"
                  onClick={() => handleColorClick(color)}
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    margin: "2px",
                  }}
                >
                  <img
                    src={color.imageUrl}
                    alt={`Color Option ${idx + 1}`}
                    style={{
                      width: "70px",
                      height: "70px",
                      border: "2px solid #ddd",
                    }}
                  />
                  <h6>{color.name}</h6>
                </div>
              ))
            ) : (
              <p>No colors available for this category.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wrl_8mm;
