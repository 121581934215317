import React from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/WallTiles.css";
import "../../styles/Parallax.css";
import img1 from "../../images/laminates/12mm/img1.jpg";
import img2 from "../../images/laminates/12mm/img2.jpg";
import img3 from "../../images/laminates/12mm/img3.jpg";
import img4 from "../../images/laminates/12mm/img4.png";
import img5 from "../../images/laminates/12mm/12mm.jpg";
import img6 from "../../images/laminates/12mm/12mm 2.jpg";
import img7 from "../../images/laminates/12mm/12mm 3.jpg";
import img8 from "../../images/laminates/12mm/12mm4.jpg";
import { Parallax } from "react-parallax";

const Laminates12 = () => {
  const navigate = useNavigate();

  const images = [
    {
      url: img1,
      text: "Effect",
      name: "Effect Laminate",
      description:
        "High-quality laminate with an elegant finish, perfect for any space.",
        desc: "Click Image to See More",
    },
    {
      url: img2,
      text: "Solid",
      name: "Solid Laminate",
      description:
        "A simple, durable, and versatile laminate for modern interiors.",
        desc: "Click Image to See More",
    },
    {
      url: img3,
      text: "Solid Chrome Plank",
      name: "Solid Chrome Plank Laminate",
      description: "A sleek chrome plank with a modern design.",
      desc: "Click Image to See More",
    },
    {
      url: img4,
      text: "Xpressions Herringbone",
      name: "Xpressions Herringbone Laminate",
      description:
        "A unique herringbone pattern that adds sophistication to your floors.",
        desc: "Click Image to See More",
    },
    {
      url: img5,
      text: "Krono Quatro",
      name: "Krono Quatro Laminate",
      description:
        "A laminate with high wear resistance, perfect for busy spaces.",
        desc: "Click Image to See More",
    },
    {
      url: img6,
      text: "Krono King Floor",
      name: "Krono King Floor Laminate",
      description: "Durable, elegant laminate with a classic wooden look.",
      desc: "Click Image to See More",
    },
    {
      url: img7,
      text: "Krono Sound",
      name: "Krono Sound Laminate",
      description:
        "Noise-reducing laminate, ideal for apartments or multi-floor buildings.",
        desc: "Click Image to See More",
    },
    {
      url: img8,
      text: "Xtreme Long Plank",
      name: "Xtreme Long Plank Laminate",
      description: "Large planks for a seamless, luxurious look in any room.",
      desc: "Click Image to See More",
    },
  ];

  const handleNavigate = (image) => {
    navigate(`/12mm_Laminate/${image.text}`, { state: { image } });
  };

  return (
    <>
      <div className="tile1">
        <div className="text">
          <div className="first">
            <p className="second">
              <h1>12mm Laminates</h1>
              Laminate wood flooring is a cost-effective alternative to
              hardwood, offering the look of natural wood at a fraction of the
              price. It is highly durable and resistant to scratches, stains,
              and moisture, making it ideal for high-traffic areas and homes
              with pets or children. Easy to install with click-and-lock
              systems, it reduces labor costs and installation time. Laminate
              flooring requires minimal maintenance and is simple to clean,
              making it a practical choice for busy households. Its wide range
              of designs, colors, and textures ensures it can mimic various wood
              types, offering versatility for different interior styles.
            </p>
            <div>
              <img src={img1} alt="Laminate Tile 1" className="main_image" />
            </div>
          </div>
        </div>
      </div>

      <div>
        {images.map((image, index) => (
          <Parallax
            key={index}
            bgImage={image.url}
            strength={300}
            bgImageStyle={{
              width: "100%",
              height: "100%",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div
              className={`section section-${index + 1}`}
              onClick={() => handleNavigate(image)}
              style={{ cursor: "pointer" }}
            >
              <div className="text-overlay">
                <h2>{image.name}</h2>
                <p className="description">{image.description}</p>
                <p >{image.desc}</p>
              </div>
              {/* <p className="img-desc">{image.desc}</p> */}
            </div>
          </Parallax>
        ))}
      </div>
    </>
  );
};

export default Laminates12;
