import React from "react";
import "../../styles/WallTiles.css";
import { Parallax } from "react-parallax";
import "../../styles/Parallax.css";
import img1 from "../../images/hardwoods/Hardwoods Herringbone/Hakwood_Herringbone.jpg";
import img2 from "../../images/hardwoods/Hardwoods Herringbone/Hakwood_Herringbone.jpg";
import img3 from "../../images/hardwoods/Hardwoods Herringbone/Ashwood_cropped.jpg";
import img4 from "../../images/hardwoods/Hardwoods Herringbone/Intasa_Herrinbone.jpg";
import img5 from "../../images/hardwoods/Hardwoods Herringbone/Esco-Herringbone.jpg";
import img6 from "../../images/hardwoods/Hardwoods Herringbone/vista_ultra_herringbone.jpg";
import { useNavigate } from "react-router-dom";
const HadrdwoodHeringbone = () => {
 const images = [
   {
     url: img1,
     text: "Hakwood Herringbone",
     name: "14mm Hardwoods",
     description: "Elegant and durable Hakwood for a premium experience.",
     desc: "Click Image to See More",
   },
   {
     url: img2,
     text: "Vista Herringbone",
     name: "14mm Hardwoods",
     description: "Classic Vista Original hardwoods for timeless interiors.",
     desc: "Click Image to See More",
   },
   {
     url: img3,
     text: "Intasa Herringbone",
     name: "14mm Hardwoods",
     description: "Wide Intasa 200mm planks for a natural wood feel.",
     desc: "Click Image to See More",
   },
   {
     url: img4,
     text: "Vista Ultra Herringbone",
     name: "14mm Hardwoods",
     description: "Extra-wide Intasa 250mm planks for spacious designs.",
     desc: "Click Image to See More",
   },
   {
     url: img5,
     text: "The Twelve",
     name: "14mm Hardwoods",
     description: "Refined Twelve series for modern and sophisticated spaces.",
     desc: "Click Image to See More",
   },
   {
     url: img6,
     text: "Esco 190mm Plank",
     name: "14mm Hardwoods",
     description: "Durable Esco 190mm planks for a polished appearance.",
     desc: "Click Image to See More",
   },
 ];
  
  const navigate = useNavigate();

  const handleNavigate = (image) => {
    if (!image || !image.text) {
      console.error("Image or text is undefined");
      return;
    }

    const textWithoutSpaces = image.text.replace(/\s+/g, "").trim();

    navigate(`/hardwoods_herringbone/${textWithoutSpaces}`, {
      state: { image },
    });
  };



  return (
    <>
      <div className="tile1">
        <div className="text">
          <div className="first">
            <p className="second">
              <h1>Hardwoods Herringbone</h1>
              Hardwood flooring is prized for its natural beauty and timeless
              appeal, adding warmth and sophistication to any space. It offers
              exceptional durability and can last for decades, with the ability
              to be sanded and refinished multiple times to restore its original
              charm. Hardwood improves indoor air quality by being free from
              allergens and synthetic materials, making it a healthier choice
              for homes. Its variety of wood species, grains, and finishes
              allows for customization to match diverse interior styles.
              Additionally, hardwood flooring increases property value, making
              it a valuable investment for homeowners.
            </p>
            <div>
              <img src={img1} alt="Laminate Tile 1" className="main_image" />
            </div>
          </div>
        </div>
      </div>

      <div>
        {images.map((image, index) => (
          <Parallax
            key={index}
            bgImage={image.url}
            strength={300}
            bgImageStyle={{
              width: "100%",
              height: "100%",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div
              className={`section section-${index + 1}`}
              onClick={() => handleNavigate(image)}
              key={index}
            >
              <div className="text-overlay">
                <h2>{image.text}</h2>
                <h3>{image.name}</h3>
                <p>{image.description}</p>
                <p >{image.desc}</p>
              </div>
              
            </div>
          </Parallax>
        ))}
      </div>
    </>
  );
};

export default HadrdwoodHeringbone;
