import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Hardwood10mmDetail = () => {
  const location = useLocation();
  const { image } = location.state || {};

  console.log(image?.text, "Category (text)");

  const [currentImage, setCurrentImage] = useState("");

  // Set initial image if available
  useEffect(() => {
    if (image?.url) {
      setCurrentImage(image.url);
    }
  }, [image]);

  // Handle the case when image is not available
  if (!image) {
    return (
      <div className="error-message">
        <p>
          Product data is not available. Please navigate from the Hardwoods
          page.
        </p>
        <Link to="/10mm_hardwoods" className="back-link">
          Back to Products
        </Link>
      </div>
    );
  }

  const { name, description, text } = image;

  console.log("Details:", name, description, text);

  // Define color options
  const colorOptions = {
   
    "Vista Ultra 18mm Plank": [
  { "name": "SEA FOG", "imageUrl": "https://www.bearfoot.ie/assets/hardwoods/18mm/vista-ultra/sea-fog/Sea-Fog-Plank_001__FocusFillWzU2MCwzOTIsIngiLDEzXQ.jpg" },
  { "name": "BLACKWING", "imageUrl": "https://www.bearfoot.ie/assets/hardwoods/18mm/vista-ultra/blackwing/Blackwing-Plank_001__FocusFillWzU2MCwzOTIsIngiLDEzXQ.jpg" },
  { "name": "PYRAMID", "imageUrl": "https://www.bearfoot.ie/assets/hardwoods/18mm/vista-ultra/pyramid/Pyramid-Handbrushed-Plank_001__FocusFillWzU2MCwzOTIsIngiLDEzXQ.jpg" }
]
,
    "Intasa 200mm Plank": [
      { name: "Chestnut", imageUrl: "https://example.com/images/chestnut.jpg" },
      { name: "Walnut", imageUrl: "https://example.com/images/walnut.jpg" },
    ],
    "Intasa 250mm Plank": [
      { name: "Chestnut", imageUrl: "https://example.com/images/chestnut.jpg" },
      { name: "Walnut", imageUrl: "https://example.com/images/walnut.jpg" },
    ],
    "The Twelve": [
      { name: "Chestnut", imageUrl: "https://example.com/images/chestnut.jpg" },
      { name: "Walnut", imageUrl: "https://example.com/images/walnut.jpg" },
    ],
    "Esco 190mm Plank": [
      { name: "Chestnut", imageUrl: "https://example.com/images/chestnut.jpg" },
      { name: "Walnut", imageUrl: "https://example.com/images/walnut.jpg" },
    ],
    "Esco 250mm Plank": [
      { name: "Chestnut", imageUrl: "https://example.com/images/chestnut.jpg" },
      { name: "Walnut", imageUrl: "https://example.com/images/walnut.jpg" },
    ],
  };

  // Dynamically filter color options based on the category (`text`)
  const filteredColorOptions = text ? colorOptions[text] || [] : [];

  console.log("Filtered Colors:", filteredColorOptions);

  const handleColorClick = (color) => {
    setCurrentImage(color.imageUrl);
  };

  return (
    <div className="product-detail">
      <Link to="/10mm_hardwoods" className="back-link">
        Back
      </Link>
      <div className="product-main">
        <img
          className="main-product-image"
          src={currentImage || "https://example.com/default-image.jpg"} // Fallback image
          alt={`Preview of ${name}`}
        />
        <div className="product-info">
          <h2>{name}</h2>
          <strong>{description}</strong>
          {/* <h5>Category: {text}</h5> */}
          <div className="colors">
            {filteredColorOptions.length > 0 ? (
              filteredColorOptions.map((color, idx) => (
                <div
                  key={idx}
                  className="color-swatch"
                  onClick={() => handleColorClick(color)}
                  
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    margin: "2px",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={color.imageUrl}
                    alt={`Color swatch of ${color.name}`}
                    style={{
                      width: "70px",
                      height: "70px",
                      border:"2px solid #ddd",
                      borderRadius: "5px",
                    }}
                  />
                  <h6>{color.name}</h6>
                </div>
              ))
            ) : (
              <p>No colors available for this category.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hardwood10mmDetail;
