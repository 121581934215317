import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Wrl_12mm = () => {
  const location = useLocation();
  const { image } = location.state || {};

  const [currentImage, setCurrentImage] = useState(image.url || "") ;

  // Safely access image

  if (!image) {
    return (
      <p>
        Product data is not available. Please navigate from the Laminates page.
      </p>
    );
  }

  const { name, description, text } = image;

  const colorOptions =  {
    "Krono King Aqua +": [
      {
        name: "Stone Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/king-floor/stone-oak/new-product-details/stone-oak1__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Venice Oak ",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/king-floor/venice-oak/venice-oak/venice-oak1__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Estate Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/king-floor/estate-oak/estate-oak/estate-oak1__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Dominican Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/king-floor/dominican-oak/dominican-oak/dominican-oak1__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Westminster Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/king-floor/westminster-oak/new-product-details/westminster-oak1__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Canyon Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/king-floor/canyon-oak/canyon-oak/canyon-oak1__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      }
    ],
    "Krono Symphonia": [
      {
        name: "Largo Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/symfonia/new-product-details/4526_rzut_z_gory_3_panele_a4_big__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Legato Oak ",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/symfonia/new-product-details/4528_rzut_z_gory_3_panele_a4_big__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Presto Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/symfonia/new-product-details/4530_rzut_z_gory_3_panele_a4_big__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Solo Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/symfonia/new-product-details/4529_rzut_z_gory_3_panele_a4_big__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Sonata Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/symfonia/new-product-details/4531_rzut_z_gory_3_panele_a4_big__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Vivo Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/symfonia/new-product-details/4527_rzut_z_gory_3_panele_a4_big__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Forte Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/symfonia/forte/Forte_40074_panel__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Allegro Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/symfonia/allegro/allegro_40244_panel__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      }
    ],
    "Empire XL Plank": [
      {
        name: "Galway",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/empire/xl-plank/galway/162-GALWAY-XL-Plank-v2__FocusFillWzU2MCwzOTIsIngiLDU5XQ.jpg",
      },
      {
        name: "Jaipur",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/empire/xl-plank/jaipur/434-JAIPUR-XL-Plank-v2__FocusFillWzU2MCwzOTIsIngiLDY3XQ.jpg",
      },
      {
        name: "Vicence",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/empire/xl-plank/vicence/435-VICENCE-XL-Plank-v2__FocusFillWzU2MCwzOTIsIngiLDY2XQ.jpg",
      },
      {
        name: "Nottingham",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/empire/xl-plank/nottingham/456-NOTTIGHAM-XL-Plank-v2__FocusFillWzU2MCwzOTIsIngiLDIzNV0.jpg",
      },
      {
        name: "Alicante",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/empire/xl-plank/alicante/518-ALICANTE-XL-Plank-v2__FocusFillWzU2MCwzOTIsIngiLDEwM10.jpg",
      },
      {
        name: "Glasgow",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/empire/xl-plank/glasgow/520_Glasgow_detail-v2__FocusFillWzU2MCwzOTIsInkiLDBd.jpg",
      },
      {
        name: "Oban",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/empire/xl-plank/oban/619-OBAN-XL-Plank-v2__FocusFillWzU2MCwzOTIsInkiLDBd.jpg",
      },
      {
        name: "Cork",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/empire/xl-plank/cork/622-CORK-XL-Plank-v2__FocusFillWzU2MCwzOTIsIngiLDQzXQ.jpg",
      }
    ],
    "Empire Herringbone": [
      {
        name: "Oban",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/empire/herringbone/new-product-details/619-OBAN-OAK_floor__FocusFillWzU2MCwzOTIsIngiLDUzXQ.jpg",
      },
      {
        name: "Galway ",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/empire/herringbone/new-product-details/521718-521756-Galway_floor__FocusFillWzU2MCwzOTIsIngiLDI5XQ.jpg",
      },
      {
        name: "Cork",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/empire/herringbone/cork/622-CORK-OAK-Panel-v2__FocusFillWzU2MCwzOTIsInkiLDg0XQ.jpg",
      },
      {
        name: "Nottingham",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/empire/herringbone/new-product-details/nottingham_floor__FocusFillWzU2MCwzOTIsIngiLDYyXQ.jpg",
      },
      {
        name: "Vicence",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/empire/herringbone/vicence/435-VICENCE-Panel__FocusFillWzU2MCwzOTIsInkiLDgyXQ.jpg",
      },
      {
        name: "Jaipur",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/empire/herringbone/jaipur/434-JAIPUR-Panel_resize__FocusFillWzU2MCwzOTIsInkiLDg0XQ.jpg",
      },
      {
        name: "Alicante",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/empire/herringbone/new-product-details/518-ALICANTE_floor__FocusFillWzU2MCwzOTIsIngiLDJd.jpg",
      },
      {
        name: "Glasgow",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/empire/herringbone/new-product-details/520-GLASGOW-OAK_floor__FocusFillWzU2MCwzOTIsIngiLDJd.jpg",
      }
    ],
  };
  const filteredColorOptions = colorOptions[text] || [];  

  const handleColorClick = (color) => {
    setCurrentImage(color.imageUrl);
  };
  return (
    <div className="product-detail">
      <Link to="/12mm_wrl" className="back-link">
        Back
      </Link>
      <div className="product-main">
        <img className="main-product-image" src={currentImage} alt={name} />
        <div className="product-info">
          <h2>{name}</h2>
          <strong>{description}</strong>
          <h5>Category: {text}</h5>

          <div className="colors">
          {filteredColorOptions.length > 0 ? (
              filteredColorOptions.map((color, idx) => (
              <div
                key={idx}
                className="color-swatch"
                onClick={() => handleColorClick(color)}
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  margin: "2px",
                }}
              >
                <img
                  src={color.imageUrl}
                  alt={`Color Option ${idx + 1}`}
                  style={{
                    width: "70px",
                    height: "70px",
                    border: "2px solid #ddd",
                  }}
                />
                <p>{color.name}</p>
              </div>
            ))) : (
              <p>No colors available for this category.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wrl_12mm;
