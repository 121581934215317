import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Laminate8MMDetail = () => {
  const location = useLocation();
  const { image } = location.state || {};

  const [currentImage, setCurrentImage] = useState(image?.url || "");

  if (!image) {
    return (
      <p>
        Product data is not available. Please navigate from the Laminates page.
      </p>
    );
  }

  const { name, description, text } = image;

  const colorOptions = {
    "Natura Line": [
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/ILGAZ.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/LENA.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/MERIC.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/TALYA.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/TREND-OAK.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/RHODES.png",
      },
    ],
    Effect: [
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2023/06/ALPINE.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2023/06/ALTAY.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2023/06/ATLAS.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2023/06/ELBRUZ.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2023/06/EVEREST.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2023/06/FUJI.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2023/06/LOGAN.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2023/06/NIRVANA.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/PAMIR.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/ROSSO.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/SOLARO.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/TIBET.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/TOROS.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/URAL.png",
      },
    ],
    "Liberty Xpressions": [
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/AMSTERDAM-PLANK.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/BERLIN-PLANK.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/COPENHAGEN-PLANK.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/DUBLIN-PLANK.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/HELISINKI-PLANK.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/LONDON-PLANK.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/MARSEILLE-PLANK.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2023/06/NIRVANA.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/ZURICH-PLANK.png",
      },
    ],
    "Krono Sigma":[
      {
        name: "Corinne Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/sigma/new-product-details/5379_rzut_z_gory_3_panele_A4_big__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Kassandra",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/sigma/new-product-details/5375_rzut_z_gory_3_panele_A4_big__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Pamphylia",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/sigma/new-product-details/5382_rzut_z_gory_3_panele_A4_big__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Kassandra",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/sigma/new-product-details/5375_rzut_z_gory_3_panele_A4_big__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Larissa",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/sigma/new-product-details/5384_rzut_z_gory_3_panele_A4_big__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Andromeda",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/sigma/new-product-details/5380_rzut_z_gory_3_panele_A4_big__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
    ],
    "Krono Exclusive": [
      {
        name: "Almeria Spruce",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/kingfloor/new-product-details/4900_rzut_z_gory_3_panele_A4_big__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Avilla",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/exclusive/new-product-details/4555_rzut_z_gory_3_panele_A4_big_RGB__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Cartagena",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/exclusive/cartagena/4557_rzut_z_gory_3_panele_A4_big_RGB-v2__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Grenada",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/exclusive/new-product-details/4556_rzut_z_gory_3_panele_A4_big_RGB__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Saragossa",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/exclusive/new-product-details/4558_rzut_z_gory_3_panele_A4_big_RGB__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Toledo",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/exclusive/new-product-details/4109_rzut_z_gory_3_panele_A4_big__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
    ],
    "Krono Testa":[
      {
        name: "Aladdin Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/testa/aladdin-oak/3309_floor__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Ariel Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/testa/new-product-details/3310_floor__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Baruch Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/testa/new-product-details/3710_floor__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Nathan Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/testa/new-product-details/4904_floor__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Ruben Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/testa/new-product-details/4582_floor__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Uriel Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/testa/new-product-details/Uriel-D3104__FocusFillWzU2MCwzOTIsIngiLDY4XQ.jpeg",
      },
    ], 
    Noblesse: [
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d3044_rift_oak.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d3180_lugano_oak.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d3522_glasgow_oak.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d4545_urban_oak.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d6114_lodge_oak.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d7091_ticino_oak.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d8013_helsinki_oak.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d8014_new_york_oak-1.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2023/09/Tonalite-Oak.jpg",
      },
    ],
  };

  const filteredColorOptions = colorOptions[text] || [];

  const handleColorClick = (color) => {
    setCurrentImage(color.imageUrl);
  };

  return (
    <div className="product-detail">
      <Link to="/8mm_Laminate" className="back-link">
        Back
      </Link>
      <div className="product-main">
        <img className="main-product-image" src={currentImage} alt={name} />
        <div className="product-info">
          <h2>{name}</h2>
          <strong>{description}</strong>
          <h5>Category: {text}</h5>

          <div className="colors">
            {filteredColorOptions.length > 0 ? (
              filteredColorOptions.map((color, idx) => (
                <div
                  key={idx}
                  className="color-swatch"
                  onClick={() => handleColorClick(color)}
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    margin: "2px",
                  }}
                >
                  <img
                    src={color.imageUrl}
                    alt={`Color Option ${idx + 1}`}
                    style={{
                      width: "70px",
                      height: "70px",
                      border: "2px solid #ddd",
                    }}
                  />
                  <h6>{color.name}</h6>
                </div>
              ))
            ) : (
              <p>No colors available for this category.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Laminate8MMDetail;
