import React, { useState } from "react";
import { Link } from "react-router-dom";

const StairDetail = () => {
  // Initialize data for the product
  const productData = {
    names: ["TIGER FREE", "TIGER WAMP", "TIGER QUEEZE", "TIGER QUEST","TIGER GRAND","TIGER BROKE","LION 'NO CAP' and GRIZZLY 'SWAG'","LION 'BRO' and GRIZZLY 'FLY' ","LION 'SIMP' and GRIZZLY 'BUG' ","LION 'SNITCH' and GRIZZLY 'BUZZ'"],
    
    categories: ["Stair Nossings", "Stair Nossings", "Stair Nossings", "Stair Nossings","Stair Nossings","Stair Nossings","Stair Nossings","Stair Nossings","Stair Nossings","Stair Nossings"],
    // text: [
    //   "SPC Flooring",
    //   "Durable Laminate",
    //   "Luxury Vinyl Plank",
    //   "Hybrid Flooring",
    //   "Engineered Wood Plank"
    // ]
  };

  // Array of 5 color options
  const colorOptions = [
    "https://www.bearfoot.ie/assets/waterproof-spc/stair-nosings/stair-nosings/tiger-free/FREE__FocusFillWzU2MCwzOTIsIngiLDEwMV0.jpg",
    "https://www.bearfoot.ie/assets/waterproof-spc/stair-nosings/stair-nosings/tiger-wamp/WAMP__FocusFillWzU2MCwzOTIsIngiLDEwMV0.jpg",
    "https://www.bearfoot.ie/assets/waterproof-spc/stair-nosings/stair-nosings/tiger-squeeze/SQUEEZE__FocusFillWzU2MCwzOTIsIngiLDEwMV0.jpg",
    "https://www.bearfoot.ie/assets/waterproof-spc/stair-nosings/stair-nosings/tiger-quest/QUEST__FocusFillWzU2MCwzOTIsIngiLDEwMV0.jpg",
    "https://www.bearfoot.ie/assets/waterproof-spc/stair-nosings/stair-nosings/tiger-grand/GRAND-CANYON__FocusFillWzU2MCwzOTIsIngiLDEwMV0.jpg",
    "https://www.bearfoot.ie/assets/waterproof-spc/stair-nosings/stair-nosings/tiger-broke/BROKE__FocusFillWzU2MCwzOTIsIngiLDEwMV0.jpg",
    "https://www.bearfoot.ie/assets/waterproof-spc/stair-nosings/stair-nosings/new-product-details/NO-CAP-SWAG__FocusFillWzU2MCwzOTIsIngiLDEwMV0.jpg",
    "https://www.bearfoot.ie/assets/waterproof-spc/stair-nosings/stair-nosings/new-product-details/BRO-FLY__FocusFillWzU2MCwzOTIsIngiLDEwMV0.jpg",
    "https://www.bearfoot.ie/assets/waterproof-spc/stair-nosings/stair-nosings/lion-simp-and-grizzly-bug/SIMP-BUG__FocusFillWzU2MCwzOTIsIngiLDEwMV0.jpg",
    "https://www.bearfoot.ie/assets/waterproof-spc/stair-nosings/stair-nosings/new-product-details/SNITCH-BUZZ__FocusFillWzU2MCwzOTIsIngiLDEwMV0.jpg",
   
  ];

  const [currentImage, setCurrentImage] = useState(colorOptions[0]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleColorClick = (colorImage, idx) => {
    setCurrentImage(colorImage);
    setCurrentIndex(idx);
  };

  return (
    <div className="product-detail">
      <Link to="/stair_nosings" className="back-link">
        Back
      </Link>
      <div className="product-main">
        <img
          className="main-product-image"
          src={currentImage}
          alt={productData.names[currentIndex]}
          style={{
            width: "300px",
            height: "300px",
            border: "1px solid #ddd",
            marginBottom: "10px",
          }}
        />
        <div className="product-info">
          <h2>{productData.names[currentIndex]}</h2>
          {/* <strong>{productData.descriptions[currentIndex]}</strong> */}
          <h5>Category: {productData.categories[currentIndex]}</h5>
          {/* <p>{productData.text[currentIndex]}</p> */}

          <div className="colors">
            {colorOptions.map((colorImage, idx) => (
              <div
                key={idx}
                className="color-swatch"
                onClick={() => handleColorClick(colorImage, idx)}
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  margin: "2px",
                }}
              >
                <img
                  src={colorImage}
                  alt={`Color Option ${idx + 1}`}
                  style={{
                    width: "70px",
                    height: "70px",
                    border: currentIndex === idx ? "2px solid blue" : "2px solid #ddd",
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StairDetail;
