import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const MaintenanceDetail = () => {
  const location = useLocation();
  const { image } = location.state || {};

  const [currentImage, setCurrentImage] = useState(image?.url || "");

  if (!image) {
    return (
      <p>
        Product data is not available. Please navigate from the Laminates page.
      </p>
    );
  }

  const { name, text1 } = image;

  const colorOptions = {
    "Hakwood Complete Maintenance": [
      {
        
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/accessories/maintenance/hakwood/Hakwood-Complete-Products-2__FitWzU2MCwzNDBd.2018.jpg",
      }
    ],
    "Esco & Zenon Maintenance": [
      {
       
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/accessories/maintenance/esco-zenon/osmo-wash-and-care__FitWzU2MCwzNDBd.jpg",
      }
    ],
    "General Maintenance": [
      {
       
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/accessories/maintenance/general/monocoat-oil-plus-__FitWzU2MCwzNDBd.jpg",
      }
    ],
    "Artelit Maintenace": [
      {
       
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/accessories/maintenance-products/artelit-glues/Artelit-PackShot__FitWzU2MCwzNDBd.jpg",
      }
    ],
  
  };

  const filteredColorOptions = colorOptions[text1] || [];

  const handleColorClick = (color) => {
    setCurrentImage(color.imageUrl);
  };

  return (
    <div className="product-detail">
      <Link to="/maintenance" className="back-link">
        Back
      </Link>
      <div className="product-main">
        <img className="main-product-image" src={currentImage} alt={name} />
        <div className="product-info">
          <h2>{name}</h2>
          {/* <strong>{description}</strong> */}
          <h5>Category: {text1}</h5>

          <div className="colors">
            {filteredColorOptions.length > 0 ? (
              filteredColorOptions.map((color, idx) => (
                <div
                  key={idx}
                  className="color-swatch"
                  onClick={() => handleColorClick(color)}
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    margin: "2px",
                  }}
                >
                  <img
                    src={color.imageUrl}
                    alt={`Color Option ${idx + 1}`}
                    style={{
                      width: "70px",
                      height: "70px",
                      border: "2px solid #ddd",
                    }}
                  />
                  <h6>{color.name}</h6>
                </div>
              ))
            ) : (
              <p>No colors available for this category.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaintenanceDetail;
