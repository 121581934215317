import React from "react";
import { Parallax } from "react-parallax";
import "../../styles/Parallax.css";
import { useNavigate } from "react-router-dom";

import img2 from "../../images/Accessories/Maintenance/Hakwood Complete Maintenance.jpg";
import img3 from "../../images/Accessories/Maintenance/Esco & Zenon Maintenance.jpg";
import img4 from "../../images/Accessories/Maintenance/General Maintenance.jpg";
import img5 from "../../images/Accessories/Maintenance/Artelit Maintenance.jpg";


// import img4 from "../images/laminates/10mm/img1.jpg";
// import img5 from "../images/laminates/10mm/img1.jpg";

const Maintenance = () => {
  const navigate = useNavigate();

  const images = [
   
    {
      url: img2,
      name: "Hakwood Complete Maintenance",
      text1: "Hakwood Complete Maintenance",
      text2: "Complete Cleaner, Oil Care and Finish Care",
      desc: "Click Image to See More",
    },
    {
      url:img3,
      name: "Esco & Zenon Maintenance",
      text1: "Esco & Zenon Maintenance",
      text2: "Osmo Wash & Care, Polyx-oil, Liquid Wax and Mop Set",
      desc: "Click Image to See More",
    },
    {
      url: img4,
      name: "General Maintenance",
      text1: "General Maintenance",
      text2: "Monocoat Oils and Soap",
      desc: "Click Image to See More",
    },
    {
      url: img5,
      name: "Artelit Maintenace",
      text1: "Artelit Maintenace",
      text2: "Artelit Glue, Primer, Adhesive, Cleaner and Spreader",
      desc: "Click Image to See More",
    },
  ];
  const handleNavigate = (image) => {

    navigate(`/maintenancedetails/${image.text1}`, { state: { image } });
  };
  return (
    <>
      <div>
      {images.map((image, index) => (
        <Parallax
          key={index}
          bgImage={image.url}
          strength={300}
          
          bgImageStyle={{
            width: "100%",
            height: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className={`section section-${index + 1}`}
           onClick={() => handleNavigate(image)}
           key={index}
           style={{ cursor: "pointer" }}>
            <div className="text-overlay">
              <h2>{image.text1}</h2>
              <h3>{image.text2}</h3>
              <p >{image.desc}</p>
            </div>
            
          </div>
        </Parallax>
      ))}
    </div>
    </>
  );
};

export default Maintenance;
