import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const UnderlaysDetail = () => {
  const location = useLocation();
  const { image } = location.state || {};

  const [currentImage, setCurrentImage] = useState(image?.url || "");

  if (!image) {
    return (
      <p>
        Product data is not available. Please navigate from the Laminates page.
      </p>
    );
  }

  const { name, text1 } = image;

  const colorOptions = {
    "Arbiton Secura Extra Aquastop Smart": [
      {
       
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/accessories/underlays/arbiton-secura-extra-aquastop-smart-underlay/ARB_SECURA-AQUASTOP-SMART_PACKSHOT__FitWzU2MCwzNDBd.jpg",
      }
    ],
    "Arbiton Multiprotec Absolute 3 in 1": [
      {
       
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/accessories/underlays/arbiton-multiprotec-absolute-3-in-1-underlay-3mm/Absolute_Packshot__FitWzU2MCwzNDBd.jpg",
      }
    ],
    "Arbiton M-Base Heat": [
      {
       
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/accessories/underlays/arbiton-m-base-heat/mbase-packshot__FitWzU2MCwzNDBd.jpg",
      }
    ],
    "Arbiton M-Base Acoustic": [
      {
       
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/accessories/underlays/m-base-acoustic/mbase_no_writing__FocusFillWzQ3NCwzMzIsIngiLDIzXQ.jpg",
      }
    ],
    "Vista Gold": [
      {
       
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/accessories/underlays/vista-gold/Underlay_014__FitWzU2MCwzNDBd.jpg",
      }
    ],
    "Vista Warm": [
      {
       
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/accessories/underlays/vista-warm/Underlay_006__FitWzU2MCwzNDBd.jpg",
      }
    ],
    "Vista Chrome": [
      {
       
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/accessories/underlays/vista-chrome/Underlay_008__FitWzU2MCwzNDBd.jpg",
      }
    ],
  };

  const filteredColorOptions = colorOptions[text1] || [];

  const handleColorClick = (color) => {
    setCurrentImage(color.imageUrl);
  };

  return (
    <div className="product-detail">
      <Link to="/underlays" className="back-link">
        Back
      </Link>
      <div className="product-main">
        <img className="main-product-image" src={currentImage} alt={name} />
        <div className="product-info">
          <h2>{name}</h2>
          {/* <strong>{description}</strong> */}
          <h5>Category: {text1}</h5>

          <div className="colors">
            {filteredColorOptions.length > 0 ? (
              filteredColorOptions.map((color, idx) => (
                <div
                  key={idx}
                  className="color-swatch"
                  onClick={() => handleColorClick(color)}
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    margin: "2px",
                  }}
                >
                  <img
                    src={color.imageUrl}
                    alt={`Color Option ${idx + 1}`}
                    style={{
                      width: "70px",
                      height: "70px",
                      border: "2px solid #ddd",
                    }}
                  />
                  <h6>{color.name}</h6>
                </div>
              ))
            ) : (
              <p>No colors available for this category.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnderlaysDetail;
