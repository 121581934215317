import React from "react";
import { useNavigate } from "react-router-dom";
import { Parallax } from "react-parallax";
import "../../styles/WallTiles.css";
import "../../styles/Parallax.css";
import img1 from "../../images/laminates/8mm/img1.jpg";
import img2 from "../../images/laminates/8mm/img2.jpg";
import img3 from "../../images/laminates/8mm/img3.jpg";
import img4 from "../../images/laminates/8mm/img4.jpg";
import img5 from "../../images/laminates/8mm/img5.jpg";
import img6 from "../../images/laminates/8mm/1.jpg";
import img7 from "../../images/laminates/8mm/krono 8mm.jpg";
import img8 from "../../images/laminates/8mm/krono 8mm 3.jpg";

const Laminates = () => {
  const navigate = useNavigate();

  const images = [
    {
      url: img1,
      text: "Effect",
      name: "Effect Laminate",
      desc: "Click Image to See More",
    },
    {
      url: img2,
      text: "Liberty Xpressions",
      name: "Liberty Xpressions Laminate",
      desc: "Click Image to See More",
    },
    {
      url: img3,
      text: "Natura Line",
      name: "Natura Line Laminate",
      desc: "Click Image to See More",
    },
    {
      url: img4,
      text: "Noblesse",
      name: "Noblesse Laminate",
      desc: "Click Image to See More",
    },
    {
      url: img5,
      text: "Noblesse",
      name: "Noblesse Variant",
      desc: "Click Image to See More",
    },
    {
      url: img6,
      text: "Krono Sigma",
      name: "Krono Sigma Laminate",
      desc: "Click Image to See More",
    },
    {
      url: img7,
      text: "Krono Exclusive",
      name: "Krono Exclusive Laminate",
      desc: "Click Image to See More",
    },
    {
      url: img8,
      text: "Krono Testa",
      name: "Krono Testa Laminate",
      desc: "Click Image to See More",
    },
  ];

  const handleNavigate = (image) => {

    navigate(`/8mm_Laminate/${image.text}`, { state: { image } });
  };

  return (
    <>
    <div className="tile1">
      <div className="text">
        <div className="first">
          <p className="second">
            <h1>8mm Laminates</h1>
            Laminate wood flooring is a cost-effective alternative to
            hardwood, offering the look of natural wood at a fraction of the
            price. It is highly durable and resistant to scratches, stains,
            and moisture, making it ideal for high-traffic areas and homes
            with pets or children. Easy to install with click-and-lock
            systems, it reduces labor costs and installation time. Laminate
            flooring requires minimal maintenance and is simple to clean,
            making it a practical choice for busy households. Its wide range
            of designs, colors, and textures ensures it can mimic various wood
            types, offering versatility for different interior styles.
          </p>
          <div>
            <img src={img1} alt="10mm Laminate Tile" className="main_image" />
          </div>
        </div>
      </div>
    </div>
    <div>
      {images.map((image, index) => (
        <Parallax
          key={index}
          bgImage={image.url}
          strength={300}
          bgImageStyle={{
            width: "100%",
            height: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div
            className={`section section-${index+1 }`}
            onClick={() => handleNavigate(image)}
            key={index}
            style={{ cursor: "pointer" }}
          >
            <div className="text-overlay">
              <h2>{image.text}</h2>
              <p >{image.desc}</p>
            </div>
           
          </div>
        </Parallax>
      ))}
    </div></>
  );
};

export default Laminates;
