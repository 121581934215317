import React from "react";
import "../../styles/WallTiles.css";
import { Parallax } from "react-parallax";
import "../../styles/Parallax.css";
import img1 from "../../images/waterresistance/8mm/krono_Enigma.jpg";
import img2 from "../../images/waterresistance/8mm/krono_movie.jpg";
import { useNavigate } from "react-router-dom";
const Laminates = () => {
  const navigate=useNavigate()
  const images = [
    {
      url: img1,
      text: "krono Enigma",
      name:"  8mm water resistance laminates",
      description:" 24h AQUA BLOCK",
      desc: "Click Image to See More",
    },
    {
      url: img2,
      text: "krono Movie",
      name: "8mm water resistance laminates",
      description: "72h AQUA ZERO",
      desc: "Click Image to See More",
    },
  ];

  const handleNavigate = (image) => {
    navigate(`/wrl_8mm/${image.text}`, { state: { image } });
  };

  return (
    <>
      <div className="tile1">
        <div className="text">
          <div className="first">
            <p className="second">
              <h1>8mm Water Resistance Laminates</h1>
              Water-resistant laminate wood flooring is ideal for areas prone to spills or moisture, 
              such as kitchens and bathrooms, as it offers protection against water damage. Its advanced 
              design prevents swelling, warping, and buckling, ensuring long-term durability and stability. 
              This flooring is easy to maintain, requiring simple cleaning without worrying about water stains
              or absorption. The variety of styles and finishes allows it to replicate natural wood aesthetics
              while providing enhanced functionality for damp environments. Additionally, water-resistant laminates
              are more affordable than waterproof options or hardwood, making them a practical and stylish solution
              for modern homes.
            </p>
            <div>
              <img src={img1} alt="Laminate Tile 1" className="main_image" />
            </div>
          </div>
        </div>
      </div>

      <div>
      {images.map((image, index) => (
        <Parallax
          key={index}
          bgImage={image.url}
          strength={300}
          
          bgImageStyle={{
            width: "100%",
            height: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className={`section section-${index + 1}`} 
          onClick={()=>handleNavigate(image)}
          key={index}
          style={{ cursor: "pointer" }}>
            <div className="text-overlay">
              <h2>{image.name}</h2>
              <h3>{image.text}</h3>
              <h3>{image.description}</h3>
              <p >{image.desc}</p>
            </div>
           
          </div>
        </Parallax>
      ))}
    </div>
    </>
  );
};

export default Laminates;
