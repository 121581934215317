import React from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/WallTiles.css";
import { Parallax } from "react-parallax";
import "../../styles/Parallax.css";
import img1 from "../../images/laminates/14mm/img1.jpg";
import img2 from "../../images/laminates/14mm/img2.jpg";
import img3 from "../../images/laminates/14mm/img3.jpg";

const Laminates14 = () => {
  const navigate = useNavigate();

  const images = [
    {
      url: img1,
      text: "Authentic",
      name: "Authentic Laminate",
      description: "A classic laminate with natural wood appearance.",
      desc: "Click Image to See More",
    },
    {
      url: img2,
      text: "Evolution",
      name: "Evolution Laminate",
      description: "A modern design for contemporary interiors.",
      desc: "Click Image to See More",
    },
    {
      url: img3,
      text: "Origin",
      name: "Origin Laminate",
      description: "Bringing traditional charm with durable laminate.",
      desc: "Click Image to See More",
    },
  ];

  const handleNavigate = (image) => {
    navigate(`/14mm_Laminate/${image.text}`, { state: { image } });
  };

  return (
    <>
      <div className="tile1">
        <div className="text">
          <div className="first">
            <p className="second">
              <h1>14mm Laminates</h1>
              Laminate wood flooring is a cost-effective alternative to
              hardwood, offering the look of natural wood at a fraction of the
              price. It is highly durable and resistant to scratches, stains,
              and moisture, making it ideal for high-traffic areas and homes
              with pets or children. Easy to install with click-and-lock
              systems, it reduces labor costs and installation time. Laminate
              flooring requires minimal maintenance and is simple to clean,
              making it a practical choice for busy households. Its wide range
              of designs, colors, and textures ensures it can mimic various wood
              types, offering versatility for different interior styles.
            </p>
            <div>
              <img src={img1} alt="Laminate Tile 1" className="main_image" />
            </div>
          </div>
        </div>
      </div>

      <div>
        {images.map((image, index) => (
          <Parallax
            key={index}
            bgImage={image.url}
            strength={300}
            bgImageStyle={{
              width: "100%",
              height: "100%",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div
              className={`section section-${index + 1}`}
              onClick={() => handleNavigate(image)}
              style={{ cursor: "pointer" }}
            >
              <div className="text-overlay">
                <h2>{image.name}</h2>
                <p className="description">{image.description}</p>
                <p >{image.desc}</p>
                </div>
              
            </div>
          </Parallax>
        ))}
      </div>
    </>
  );
};

export default Laminates14;
