import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Laminate10MMDetail = () => {
  const location = useLocation();
  const { image } = location.state || {};

  const [currentImage, setCurrentImage] = useState(image.url || "");

  // Safely access image

  if (!image) {
    return (
      <p>
        Product data is not available. Please navigate from the Laminates page.
      </p>
    );
  }

  const { name, description, text } = image;

  const colorOptions = {
    "Majestic": [
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/D3033-Zermatt-Oak.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/D3913-Oak-Silica.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/D3917-Linguria-Oak.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/D6108-Bordeaux-Oak.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/D6109-Normandie-Oak.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/D40301-St.-Mortiz-Oak.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/D80631-Disentis-Oak.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/D80641-Chur-Oak.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/D80651-Tamins-Oak.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/D80661-Realp-Oak.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/D80671-Sedrun-Oak.jpg",
      },
    ],
    Mood: [
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/MALTA.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/MARE.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/MARINE.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/MARVEL.png",
       },
    ],
    "Krono Marine": [
      {
        name: "Adriatic Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/marine/new-product-details/3793_rzut_z_gory_3_panele_A4_big_RGB_net__FocusFillWzU2MCwzOTIsInkiLDJd.jpg",
      },
      {
        name: "Atlantic Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/marine/new-product-details/3788_rzut_z_gory_3_panele_a4_big_rgb__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Baltic Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/marine/new-product-details/3787_rzut_z_gory_3_panele_A4_big_RGB__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Caspian Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/marine/new-product-details/4563_rzut_z_gory_3_panele_A4_big_RGB__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Japanese Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/marine/new-product-details/4564_rzut_z_gory_3_panele_A4_big_RGB__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Pacific Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/marine/new-product-details/3820_rzut_z_gory_3_panele_A4_big_RGB__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
    ],
    "Krono Aroma":[
      {
        name: "Lavender Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/aroma/lavender-oak/3946_3_panele_www2__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Barley Walnut",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/aroma/barley-walnut/3882_rzut_z_gory_3_panele_A4_big__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Mint Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/aroma/barley-walnut-2/3881_6_paneli_www__FocusFillWzU2MCwzOTIsInkiLDM4XQ.jpg",
      },
      {
        name: "Vanilla Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/aroma/new-product-details/3328_rzut_z_gory_3_panele_format_A4_big__FocusFillWzU2MCwzOTIsInkiLDJd.jpg",
      },
    ],
    "Xtreme Wide & Long": [
      {
        name: "Castle Oak Silver",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/x-treme/x-treme-plus/castle-oak-silver/3__FocusFillWzU2MCwzOTIsIngiLDE2MV0.jpg",
      },
      {
        name: "Bergen Oak Beige",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/x-treme/x-treme-plus/bergen-oak-beige/6__FocusFillWzU2MCwzOTIsIngiLDE2MV0.jpg",
      },
      {
        name: "Oak Nature",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/x-treme/wide-long/new-product-details/oak-nature1__FocusFillWzU2MCwzOTIsInkiLDIwMF0.jpg",
      },
      {
        name: "Oak Brown",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/x-treme/wide-long/oak-brown/Oak-brown1__FocusFillWzU2MCwzOTIsInkiLDIwMF0.jpg",
      },
      {
        name: "Oak Light",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/x-treme/wide-long/oak-light/Oak-light1__FocusFillWzU2MCwzOTIsInkiLDIwMF0.jpg",
      },
      {
        name: "Oak Creme",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/x-treme/wide-long/oak-creme/Oak-creme1__FocusFillWzU2MCwzOTIsInkiLDIwMF0.jpg",
      },
    ],
  };

  const filteredColorOptions = colorOptions[text] || [];

  const handleColorClick = (color) => {
    setCurrentImage(color.imageUrl);
  };

  return (
    <div className="product-detail">
      <Link to="/8mm_Laminate" className="back-link">
        Back
      </Link>
      <div className="product-main">
        <img className="main-product-image" src={currentImage} alt={name} />
        <div className="product-info">
          <h2>{name}</h2>
          <strong>{description}</strong>
          <h5>Category: {text}</h5>

          <div className="colors">
            {filteredColorOptions.length > 0 ? (
              filteredColorOptions.map((color, idx) => (
                <div
                  key={idx}
                  className="color-swatch"
                  onClick={() => handleColorClick(color)}
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    margin: "2px",
                  }}
                >
                  <img
                    src={color.imageUrl}
                    alt={`Color Option ${idx + 1}`}
                    style={{
                      width: "70px",
                      height: "70px",
                      border: "2px solid #ddd",
                    }}
                  />
                  <h6>{color.name}</h6>
                </div>
              ))
            ) : (
              <p>No colors available for this category.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Laminate10MMDetail;
