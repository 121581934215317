import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const TrimsDetail = () => {
  const location = useLocation();
  const { image } = location.state || {};

  const [currentImage, setCurrentImage] = useState(image?.url || "");

  if (!image) {
    return (
      <p>
        Product data is not available. Please navigate from the Laminates page.
      </p>
    );
  }

  const { name, text1 } = image;

  const colorOptions = {
    "3-in-1 Flat": [
      {
        name:"Silver A1 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS30-A1-Silver__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Silver A1 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS30-A1-Silver-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Titanium A3 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS30-A3-Titanium__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Titanium A3 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS30-A3-Titanium-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Linburg Oak CS04 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS30-4-Lingburg-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Linburg Oak CS04 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS30-4-Lingburg-Oak-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Dark Oak CS12 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS30-12-Dark-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Dark Oak CS12 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS30-12-Dark-Oak-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Wenge Africa CS14 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS30-14-Wenge__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Wenge Africa CS14 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS30-14-Wenge-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Ontario Oak CS19 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS30-19-Ontario-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Ontario Oak CS19 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS30-19-Ontario-Oak-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Modena Oak CS23 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS30-23-Modena-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Modena Oak CS23 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS30-23-Modena-Oak-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Snow Oak CS25 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS30-25-Snow-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Snow Oak CS25 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS30-25-Snow-Oak-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Buffalo Oak CS26 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS30-26-Buffalo-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Buffalo Oak CS26 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS30-26-Buffalo-Oak-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Santana Oak CS29 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS30-29-Santana-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Santana Oak CS29 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS30-29-Santana-Oak-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Odessa Oak CS31 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS30-31-Odessa-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Odessa Oak CS31 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS30-31-Odessa-Oak-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Silver A1 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS30-A1-Silver-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Corona Oak CS39 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS30-39-Corona-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Corona Oak CS39 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS30-39-Corona-Oak-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Sandy Oak CS44 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS30-44-Sandy-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Sandy Oak CS44 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS30-44-Sandy-Oak-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Philadelphia Oak CS46 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS30-46-Philadelphia-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Philadelphia Oak CS46 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS30-46-Philadelphia-Oak-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Loft Oak CS03 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/3_Loft-Oak_CS30__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"LofT Oak CS03 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/3_Loft-Oak_CS30-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Noble Oak CS65 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/65_Noble-Oak_CS30__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Noble Oak CS65 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/65_Noble-Oak_CS30-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Oak Full Plank CS14 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS30-31-Odessa-Oak-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Oak Full Plan CS14 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS30-31-Odessa-Oak-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Selma Oak CS48 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/48_Selma-Oak_CS30__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Selma Oak CS48 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/48_Selma-Oak_CS30-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Tasmanian Oak CS49 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/49_Tasmanian-Oak_CS30__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Tasmanian Oak CS49 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/49_Tasmanian-Oak_CS30-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Canberra CS50 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/50_Canberra-Oak_CS30__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Northern Ash CS02 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/2_Northern-Ash_CS30-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Boston Oak CS27 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/27_Boston-Oak_CS30__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Boston Oak CS27 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/27_Boston-Oak_CS30-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Yankee Oak CS153 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/153_Yankee-Oak_CS30__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Yankee Oak CS153 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/153_Yankee-Oak_CS30-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Black CS30 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/F129_F179__FocusFillWzQ3NCwzMzIsInkiLDcwXQ.jpg",
      },
      {
        name:"Black CS30 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/F129_F179-v2__FocusFillWzQ3NCwzMzIsInkiLDcwXQ.jpg",
      },
     

    ],
    "3-in-1 Reducer": [
      {
        name:"SILVER A1 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0009_Aliminium__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"SILVER A1 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0009_Aliminium-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"TITANIUM A3 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0000_Titanium__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"TITANIUM A3 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0000_Titanium-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Linburg Oak CS04 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_Lingburg__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Linburg Oak CS04 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_Lingburg-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Dark Oak CS12 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0012_Dark-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Dark Oak CS12 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0012_Dark-Oak-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Wenge Africa CS14 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0025_Wenge__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Wenge Africa CS14 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0025_Wenge-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Ontario Oak CS19 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0024_Ontario__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Ontario Oak CS19 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0024_Ontario-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Modena Oak CS23 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0001_Mondena__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Modena Oak CS23 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0001_Mondena-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Snow Oak CS25 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0018_Snow__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Snow Oak CS25 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0018_Snow-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Buffalo Oak CS26 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0022_Buffalo__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Buffalo Oak CS26 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0022_Buffalo-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Santana Oak CS29 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0021_Santana__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Santana Oak CS29 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0021_Santana-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Odessa Oak CS31 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0020_Odessa__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Odessa Oak CS31 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0020_Odessa-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Corona Oak CS39 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0017_Corona-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Corona Oak CS39 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0017_Corona-Oak-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Sandy Oak CS44 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0013_Sandy-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Sandy Oak CS44 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0013_Sandy-Oak-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Philadelphia Oak CS46 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0027_Philadelphia__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Philadelphia Oak CS46 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0027_Philadelphia-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"LOFT OAK CS03 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0011_Loft-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"LOFT OAK CS03 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0011_Loft-Oak-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"NOBLE OAK CS65 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0005_Noble-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"NOBLE OAK CS65 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0005_Noble-Oak-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"OAK FULL PLANK 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS30-31-Odessa-Oak-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"OAK FULL PLANK 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS30-31-Odessa-Oak-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"SELMA OAK CS48 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0002_Selma-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"SELMA OAK CS48 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0002_Selma-Oak-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"TASMANIAN OAK CS49 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0003_Tasmanian-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"TASMANIAN OAK CS49 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0003_Tasmanian-Oak-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"CANBERRA OAK CS50 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0004_Canberra-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"CANBERRA OAK CS50 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0004_Canberra-Oak-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"NORTHERN ASH CS02 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0010_Northern-Ash__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"NORTHERN ASH CS02 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0010_Northern-Ash-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"BOSTON OAK CS27 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0015_Boston-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"BOSTON OAK CS27 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0015_Boston-Oak-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"YANKEE OAK CS153 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0007_Yankee-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"YANKEE OAK CS153 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS37_0007_Yankee-Oak-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"BLACK CS37 930mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/F031_F080__FocusFillWzQ3NCwzMzIsInkiLDcwXQ.jpg",
      },
      {
        name:"BLACK CS37 1860mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/F031_F080-v2__FocusFillWzQ3NCwzMzIsInkiLDcwXQ.jpg",
      }
    ],
    "SA Angle": [
      {
        name:"SILVER A1 2000mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS25-A1-Silver-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"TITANIUM A3 2000mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS25-A3-Titanium__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Linburg Oak CS04 2000mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS25-4-Lingburg-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Dark Oak CS12 2000mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS25-12-Dark-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Wenge Africa CS14 2000mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS25-14-Wenge__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Ontario Oak CS19 2000mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS25-19-Ontario-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Modena Oak CS23 2000mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS25-23-Modena-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Snow Oak CS25 2000mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS25-25-Snow-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Buffalo Oak CS26 2000mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS25-26-Buffalo-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Buffalo Oak CS26 2000mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS25-26-Buffalo-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Santana Oak CS29 2000mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS25-29-Santana-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Odessa Oak CS31 2000mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS25-31-Odessa-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Corona Oak CS39 2000mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS25-39-Corona-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Sandy Oak CS44 2000mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS25-44-Sandy-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Philadelphia Oak CS46 2000mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS25-46-Philadelphia-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"Selma Oak 2000mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/48_Selma-Oak_CS_25__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"LOFT OAK 2000mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/3_Loft-Oak_CS_25__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"NOBLE OAK 2000mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/65_Noble-Oak_CS_25__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"OAK FULL PLANK 2000mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS25-31-Odessa-Oak__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"SELMA OAK 2000mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/48_Selma-Oak_CS_25-v2__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"TASMANIAN OAK 2000mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/49_Tasmanian-Oak_CS_25__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"CANBERRA 2000mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/50_Canberra-Oak_CS_25__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"NORTHERN ASH 2000mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/2_Northern-Ash_CS_25__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"BOSTON OAK 2000mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/27_Boston-Oak_CS_25__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"YANKEE OAK 2000mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/153_Yankee-Oak_CS_25__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"PR6K Flat SILVER profile 2.7mtr",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/CS25-A1-Silver__FocusFillWzQ3NCwzMzIsInkiLDcxXQ.jpg",
      },
      {
        name:"BLACK CS25 2000mm",
        imageUrl:
          "https://www.bearfoot.ie/assets/Uploads/F375__FocusFillWzQ3NCwzMzIsInkiLDcwXQ.jpg",
      },
    ]
   
  };

  const filteredColorOptions = colorOptions[text1] || [];

  const handleColorClick = (color) => {
    setCurrentImage(color.imageUrl);
  };

  return (
    <div className="product-detail">
      <Link to="/trims" className="back-link">
        Back
      </Link>
      <div className="product-main">
        <img className="main-product-image" src={currentImage} alt={name} />
        <div className="product-info">
          <h2>{name}</h2>
          {/* <strong>{description}</strong> */}
          <h5>Category: {text1}</h5>

          <div className="colors">
            {filteredColorOptions.length > 0 ? (
              filteredColorOptions.map((color, idx) => (
                <div
                  key={idx}
                  className="color-swatch"
                  onClick={() => handleColorClick(color)}
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    margin: "2px",
                  }}
                >
                  <img
                    src={color.imageUrl}
                    alt={`Color Option ${idx + 1}`}
                    style={{
                      width: "70px",
                      height: "70px",
                      border: "2px solid #ddd",
                    }}
                  />
                  <h6>{color.name}</h6>
                </div>
              ))
            ) : (
              <p>No colors available for this category.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrimsDetail;
