import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Hardwood12mmDetail = () => {
  const location = useLocation();
  const { image } = location.state || {};

  console.log(image?.text, "Category (text)");

  const [currentImage, setCurrentImage] = useState("");

  // Set initial image if available
  useEffect(() => {
    if (image?.url) {
      setCurrentImage(image.url);
    }
  }, [image]);

  // Handle the case when image is not available
  if (!image) {
    return (
      <div className="error-message">
        <p>
          Product data is not available. Please navigate from the Hardwoods
          page.
        </p>
        <Link to="/12mm_hardwoods" className="back-link">
          Back to Products
        </Link>
      </div>
    );
  }

  const { name, description, text } = image;

  // Define color options
  const colorOptions = {
   "Vista XL 20mm Plank": [
  { "name": "White Oak Lacquered", "imageUrl": "https://www.bearfoot.ie/assets/floors/vista/xl/white-oak-lacquered/Vista-XL-Lacquered-3-v2__FocusFillWzU2MCwzOTIsInkiLDE0XQ.jpg" },
  { "name": "Brush and Lacquered Oak", "imageUrl": "https://www.bearfoot.ie/assets/floors/vista/xl/brush-oak-matt-lacquered/Vista-XL-T500-Brushed-Lac__FocusFillWzU2MCwzOTIsIngiLDE0XQ.jpg" }
]
,
    Hakwood: [
      {
        name: "Chestnut",
        imageUrl:
          "https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg",
      },
      { name: "Walnut", imageUrl: "https://example.com/images/walnut.jpg" },
    ],
    "Intasa 200mm Plank": [
      { name: "Chestnut", imageUrl: "https://example.com/images/chestnut.jpg" },
      { name: "Walnut", imageUrl: "https://example.com/images/walnut.jpg" },
    ],
    "Intasa 250mm Plank": [
      { name: "Chestnut", imageUrl: "https://example.com/images/chestnut.jpg" },
      { name: "Walnut", imageUrl: "https://example.com/images/walnut.jpg" },
    ],
    "The Twelve": [
      { name: "Chestnut", imageUrl: "https://example.com/images/chestnut.jpg" },
      { name: "Walnut", imageUrl: "https://example.com/images/walnut.jpg" },
    ],
    "Esco 190mm Plank": [
      { name: "Chestnut", imageUrl: "https://example.com/images/chestnut.jpg" },
      { name: "Walnut", imageUrl: "https://example.com/images/walnut.jpg" },
    ],
    "Esco 250mm Plank": [
      { name: "Chestnut", imageUrl: "https://example.com/images/chestnut.jpg" },
      { name: "Walnut", imageUrl: "https://example.com/images/walnut.jpg" },
    ],
  };

  // Dynamically filter color options based on the category (`text`)
  const filteredColorOptions = text ? colorOptions[text] || [] : [];


  const handleColorClick = (color) => {
    setCurrentImage(color.imageUrl);
  };

  return (
    <div className="product-detail">
      <Link to="/12mm_hardwoods" className="back-link">
        Back
      </Link>
      <div className="product-main">
        <img
          className="main-product-image"
          src={currentImage || "https://example.com/default-image.jpg"} // Fallback image
          alt={`Preview of ${name}`}
        />
        <div className="product-info">
          <h2>{name}</h2>
          <strong>{description}</strong>
          <h5>Category: {text}</h5>
          <div className="colors">
            {filteredColorOptions.length > 0 ? (
              filteredColorOptions.map((color, idx) => (
                <div
                  key={idx}
                  className="color-swatch"
                  onClick={() => handleColorClick(color)}
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    margin: "2px",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={color.imageUrl}
                    alt={`Color swatch of ${color.name}`}
                    style={{
                      width: "70px",
                      height: "70px",
                      border: "2px solid #ddd",
                      borderRadius: "5px",
                    }}
                  />
                  <h6>{color.name}</h6>
                </div>
              ))
            ) : (
              <p>No colors available for this category.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hardwood12mmDetail;
