import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Hardwood8mmDetail = () => {
  const location = useLocation();
  const { image } = location.state || {};

  console.log(image?.text, "Category (text)");

  const [currentImage, setCurrentImage] = useState("");

  // Set initial image if available
  useEffect(() => {
    if (image?.url) {
      setCurrentImage(image.url);
    }
  }, [image]);

  // Handle the case when image is not available
  if (!image) {
    return (
      <div className="error-message">
        <p>
          Product data is not available. Please navigate from the Hardwoods
          page.
        </p>
        <Link to="/8mm_hardwoods" className="back-link">
          Back to Products
        </Link>
      </div>
    );
  }

  const { name, description, text } = image;

  // Define color options
  const colorOptions = {
    "Vista Original": [
      {
        name: "SALSA",
        imageUrl:
          "https://www.bearfoot.ie/assets/hardwoods/14mm/vista-original/salsa/Salsa_Plank1__FocusFillWzU2MCwzOTIsIngiLDEzXQ.jpg",
      },
      {
        name: "CAJUN",
        imageUrl:
          "https://www.bearfoot.ie/assets/hardwoods/14mm/vista-original/cajun/Cajun-Plank__FocusFillWzU2MCwzOTIsInkiLDE0XQ.jpg",
      },
      {
        name: "NUTMEG",
        imageUrl:
          "https://www.bearfoot.ie/assets/hardwoods/14mm/vista-original/nutmeg/Nutmeg-plank__FocusFillWzU2MCwzOTIsInkiLDIxXQ.jpg",
      },
      {
        name: "LACQUERED OAK",
        imageUrl:
          "https://www.bearfoot.ie/assets/hardwoods/14mm/vista-original/lacquered-oak/t100a__FocusFillWzU2MCwzOTIsInkiLDE0XQ.jpg",
      },
      {
        name: "Brushed & Lacquered Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/hardwoods/14mm/vista-original/brushed-and-lacquered-oak/t501b__FocusFillWzU2MCwzOTIsIngiLDI2XQ.jpg",
      },
      {
        name: "COCOA",
        imageUrl:
          "https://www.bearfoot.ie/assets/hardwoods/14mm/vista-original/cocoa/Cocoa-plank__FocusFillWzU2MCwzOTIsInkiLDQzXQ.jpg",
      },
      {
        name: "SAGE",
        imageUrl:
          "https://www.bearfoot.ie/assets/hardwoods/14mm/vista-original/sage/Sage-Plank__FocusFillWzU2MCwzOTIsInkiLDU3XQ.jpg",
      },
    ],

    Hakwood: [
      {
        name: "Brave",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/hakwood/brave/new-product-details/BRAVE_Lite_standard_F__FocusFillWzU2MCwzOTIsInkiLDE0XQ.jpg",
      },
      {
        name: "Brisk",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/hakwood/brisk/brisk/BRISK_Lite_exclusive_F__FocusFillWzU2MCwzOTIsInkiLDE0XQ.jpg",
      },
      {
        name: "Calm",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/hakwood/calm/calm/CALM_Lite_standard_F__FocusFillWzU2MCwzOTIsInkiLDE0XQ.jpg",
      },
      {
        name: "Charisma",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/hakwood/charisma/charisma/CHARISMA_Lite_standard_F__FocusFillWzU2MCwzOTIsInkiLDE0XQ.jpg",
      },
      {
        name: "Divine",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/hakwood/devine/devine/DIVINE_Lite_standard_F__FocusFillWzU2MCwzOTIsInkiLDE0XQ.jpg",
      },
      {
        name: "Grace",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/hakwood/grace/grace/GRACE_Lite_standard_F__FocusFillWzU2MCwzOTIsInkiLDE0XQ.jpg",
      },
      {
        name: "Legacy",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/hakwood/legacy/legacy/LEGACY_Lite_standard_F__FocusFillWzU2MCwzOTIsInkiLDE0XQ.jpg",
      },
      {
        name: "Vital",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/hakwood/vital/vital/VITAL_Lite_standard_F__FocusFillWzU2MCwzOTIsInkiLDE0XQ.jpg",
      },
      {
        name: "Bespoke Hakwood Colours",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/hakwood/special-order-colours/hakwoodcolours__FocusFillWzU2MCwzOTIsIngiLDE4Nl0.jpg",
      },
    ],

    "Intasa 200mm Plank": [
      {
        name: "Invisible",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/intasa/200mm-plank/invisible/INVISIBLE-3__FocusFillWzU2MCwzOTIsIngiLDE0XQ.jpg",
      },
      {
        name: "Whiskey",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/intasa/200mm-plank/whiskey/WHISKY-3__FocusFillWzU2MCwzOTIsIngiLDEzXQ.jpg",
      },
      {
        name: "Barrell",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/intasa/200mm-plank/barrell/BARREL-2__FocusFillWzU2MCwzOTIsIngiLDE0XQ.jpg",
      },
    ],
    "Intasa 250mm Plank": [
      {
        name: "Invisible",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/intasa/200mm-plank/invisible/INVISIBLE-3__FocusFillWzU2MCwzOTIsIngiLDE0XQ.jpg",
      },
      {
        name: "Whiskey",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/intasa/200mm-plank/whiskey/WHISKY-3__FocusFillWzU2MCwzOTIsIngiLDEzXQ.jpg",
      },
      {
        name: "Barrell",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/intasa/200mm-plank/barrell/BARREL-2__FocusFillWzU2MCwzOTIsIngiLDE0XQ.jpg",
      },
    ],
    "The Twelve": [
      {
        name: "Alice",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/the-twelve/alice/new-product-details/THETWELVE-PRODUCT-01-Alice-Detail__FocusFillWzU2MCwzOTIsIngiLDEzXQ.jpg",
      },
      {
        name: "Bill",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/the-twelve/alice-2/alice-2/THETWELVE-PRODUCT-02-Bill-Detail__FocusFillWzU2MCwzOTIsIngiLDEzXQ.jpg",
      },
      {
        name: "Charles",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/the-twelve/charles/bill-2/THETWELVE-PRODUCT-03-Charles-Detail__FocusFillWzU2MCwzOTIsIngiLDEzXQ.jpg",
      },
      {
        name: "David",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/the-twelve/david/david/THETWELVE-PRODUCT-04-David-Detail__FocusFillWzU2MCwzOTIsIngiLDEzXQ.jpg",
      },
      {
        name: "Edward",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/the-twelve/edward/ellen/Edward-Detail-HR__FocusFillWzU2MCwzOTIsIngiLDEzXQ.jpg",
      },
      {
        name: "Florence",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/the-twelve/florence/francis/Florence-Detail-HR__FocusFillWzU2MCwzOTIsIngiLDEzXQ.jpg",
      },
      {
        name: "Gabriel",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/the-twelve/gabriel/george/Gabriel-Detail-HR__FocusFillWzU2MCwzOTIsIngiLDEzXQ.jpg",
      },
      {
        name: "Hannah",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/the-twelve/hannah/henry/Hannah-Detail-HR__FocusFillWzU2MCwzOTIsIngiLDEzXQ.jpg",
      },
      {
        name: "Irene",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/the-twelve/irene/irene/THETWELVE-PRODUCT-09-Irene-Detail__FocusFillWzU2MCwzOTIsIngiLDEzXQ.jpg",
      },
      {
        name: "Jack",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/the-twelve/jack/jack/THETWELVE-PRODUCT-10-Jack-Detail__FocusFillWzU2MCwzOTIsIngiLDEzXQ.jpg",
      },
      {
        name: "Karen",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/the-twelve/karen/karen/THETWELVE-PRODUCT-11-Karen-Detail__FocusFillWzU2MCwzOTIsIngiLDEzXQ.jpg",
      },
      {
        name: "Lea",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/the-twelve/lea/lea/THETWELVE-PRODUCT-12-Lea-Detail__FocusFillWzU2MCwzOTIsIngiLDEzXQ.jpg",
      },
    ],
    "Esco 190mm Plank": [
      {
        name: "Gothic Brushed",
        imageUrl:
          "https://www.bearfoot.ie/assets/hardwoods/14mm/esco-190mm-plank/gothic/gothic-plank__FocusFillWzU2MCwzOTIsIngiLDE3XQ.jpg",
      },
      {
        name: "Smoked Basecoat",
        imageUrl:
          "https://www.bearfoot.ie/assets/hardwoods/14mm/esco-190mm-plank/smoked-basecoat/smoked-basecoat__FocusFillWzU2MCwzOTIsIngiLDE3XQ.jpg",
      },
      {
        name: "Smoked Washy Grey",
        imageUrl:
          "https://www.bearfoot.ie/assets/hardwoods/14mm/esco-190mm-plank/smoked-washy-grey/Smoked-washy-grey__FocusFillWzU2MCwzOTIsIngiLDE3XQ.jpg",
      },
      {
        name: "Natural White",
        imageUrl:
          "https://www.bearfoot.ie/assets/hardwoods/14mm/esco-190mm-plank/natural-white/natural-white-brushed-plank__FocusFillWzU2MCwzOTIsIngiLDE3XQ.jpg",
      },
      {
        name: "Smoked Natural",
        imageUrl:
          "https://www.bearfoot.ie/assets/hardwoods/14mm/esco-190mm-plank/new-product-details/SmokedNatPlank__FocusFillWzU2MCwzOTIsIngiLDE2XQ.png",
      },
      {
        name: "Raw Look",
        imageUrl:
          "https://www.bearfoot.ie/assets/hardwoods/14mm/esco-190mm-plank/new-product-details/RawLookPlank__FocusFillWzU2MCwzOTIsIngiLDE2XQ.png",
      },
    ],
    "Esco 225mm Plank": [
  { "name": "Smoked White Harfa Vintage", "imageUrl": "https://www.bearfoot.ie/assets/floors/esco/extra-harfa-vintage/smoked-white/smoked-white-244mm__FocusFillWzU2MCwzOTIsInkiLDld.jpg" },
  { "name": "Smoked Basecoat", "imageUrl": "https://www.bearfoot.ie/assets/hardwoods/14mm/esco-225mm-plank/natural-white/smoked-basecoat__FocusFillWzU2MCwzOTIsIngiLDE3XQ.jpg" }
]
,
    
  };

  // Dynamically filter color options based on the category (`text`)
  const filteredColorOptions = text ? colorOptions[text] || [] : [];

  console.log("Filtered Colors:", filteredColorOptions);

  const handleColorClick = (color) => {
    setCurrentImage(color.imageUrl);
  };

  return (
    <div className="product-detail">
      <Link to="/8mm_hardwoods" className="back-link">
        Back
      </Link>
      <div className="product-main">
        <img
          className="main-product-image"
          src={currentImage} // Fallback image
          alt={`Preview of ${name}`}
        />
        <div className="product-info">
          <h2>{name}</h2>
          <strong>{description}</strong>
          {/* <h5>Category: {text}</h5> */}
          <div
            className="colors"
            
          >
            {filteredColorOptions.length > 0 ? (
              filteredColorOptions.map((color, idx) => (
                <div
                  key={idx}
                  className="color-swatch"
                  onClick={() => handleColorClick(color)}
                  
                  style={{
                    width: "70px",
                    height: "70px",
                    cursor: "pointer",
                    display: "inline-block",
                    margin: "2px",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={color.imageUrl}
                    alt={`Color swatch of ${color.name}`}
                    style={{
                      width: "70px",
                      height: "70px",
                      border: "2px solid #ddd",
                      borderRadius: "5px",
                    }}
                  />
                  <h6>{color.name}</h6>
                </div>
              ))
            ) : (
              <p>No colors available for this category.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hardwood8mmDetail;
