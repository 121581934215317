import React, { useState } from "react";
import { Link } from "react-router-dom";

const SPC8MMDetail = () => {
  // Initialize data for the product
  const productData = {
    names: ["BUG", "BUZZ", "FLY", "SWAG"],
    
    categories: ["Grizzly 5.5mm", "Grizzly 5.5mm", "Grizzly 5.5mm", "Grizzly 5.5mm"],
    // text: [
    //   "SPC Flooring",
    //   "Durable Laminate",
    //   "Luxury Vinyl Plank",
    //   "Hybrid Flooring",
    //   "Engineered Wood Plank"
    // ]
  };

  // Array of 5 color options
  const colorOptions = [
    "https://www.bearfoot.ie/assets/waterproof-spc/5-5mm/republic-grizzly/bug/Bug_001__FocusFillWzU2MCwzOTIsInkiLDg0XQ.jpg",
    "https://www.bearfoot.ie/assets/waterproof-spc/5-5mm/republic-grizzly/buzz/Buzz_001__FocusFillWzU2MCwzOTIsInkiLDg0XQ.jpg",
    "https://www.bearfoot.ie/assets/waterproof-spc/5-5mm/republic-grizzly/fly/Fly_001__FocusFillWzU2MCwzOTIsInkiLDg0XQ.jpg",
    "https://www.bearfoot.ie/assets/waterproof-spc/5-5mm/republic-grizzly/swag/Swag_001__FocusFillWzU2MCwzOTIsInkiLDg0XQ.jpg",
   
  ];

  const [currentImage, setCurrentImage] = useState(colorOptions[0]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleColorClick = (colorImage, idx) => {
    setCurrentImage(colorImage);
    setCurrentIndex(idx);
  };

  return (
    <div className="product-detail">
      <Link to="/8mm_wps" className="back-link">
        Back
      </Link>
      <div className="product-main">
        <img
          className="main-product-image"
          src={currentImage}
          alt={productData.names[currentIndex]}
          style={{
            width: "300px",
            height: "300px",
            border: "1px solid #ddd",
            marginBottom: "10px",
          }}
        />
        <div className="product-info">
          <h2>{productData.names[currentIndex]}</h2>
          {/* <strong>{productData.descriptions[currentIndex]}</strong> */}
          <h5>Category: {productData.categories[currentIndex]}</h5>
          {/* <p>{productData.text[currentIndex]}</p> */}

          <div className="colors">
            {colorOptions.map((colorImage, idx) => (
              <div
                key={idx}
                className="color-swatch"
                onClick={() => handleColorClick(colorImage, idx)}
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  margin: "2px",
                }}
              >
                <img
                  src={colorImage}
                  alt={`Color Option ${idx + 1}`}
                  style={{
                    width: "70px",
                    height: "70px",
                    border: currentIndex === idx ? "2px solid blue" : "2px solid #ddd",
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SPC8MMDetail;
