import React from "react";
import { Parallax } from "react-parallax";
import "../../styles/Parallax.css";
import { useNavigate } from "react-router-dom";

import img2 from "../../images/Accessories/Underlays/arbton_secura.jpg";
import img3 from "../../images/Accessories/Underlays/Arbiton Multiprotec Absolute 3 in 1.jpg";
import img4 from "../../images/Accessories/Underlays/Arbiton M-Base Heat.jpg";
import img5 from "../../images/Accessories/Underlays/Arbiton M-Base Acoustic.jpg";
import img6 from "../../images/Accessories/Underlays/Vista Gold.png";
import img7 from "../../images/Accessories/Underlays/Vista Warm.png";
import img8 from "../../images/Accessories/Underlays/Vista Chrome.png";
// import img4 from "../images/laminates/10mm/img1.jpg";
// import img5 from "../images/laminates/10mm/img1.jpg";

const Underlays = () => {
   const navigate = useNavigate();
  
  const images = [
   
    {
      url: img2,
      text1: "Arbiton Secura Extra Aquastop Smart",
      text2: "3 in 1 substrate underlay with integrated Aquastop vapour barrier and tape",
      desc: "Click Image to See More",
    },
    {
      url:img3,
      text1: "Arbiton Multiprotec Absolute 3 in 1",
      text2: "3mm substrate underlay with integrated Aquastop vapour barrier and tape",
      desc: "Click Image to See More",
    },
    {
      url:img4,
     text1: "Arbiton M-Base Heat",
      text2: "Ideal to place above the under-floor heating - the lowest heat resistance coefficient",
      desc: "Click Image to See More",
    },
    {
      url:img5,
      text1: "Arbiton M-Base Acoustic",
      text2: "M-BASE ACOUSTIC is sound proofing material that also reduces the airborne noise within your room",
      desc: "Click Image to See More",
    },
    {
      url:img6,
      text1: "Vista Gold",
      text2: "High Quality EVA with integrated ALU-Damp-Proofing",
      desc: "Click Image to See More",
    },
    {
      url:img7,
      text1: "Vista Warm",
      text2: "Heavy Duty Radiant IXPE with PE-Damp-Proofing",
      desc: "Click Image to See More",
    },
    {
      url:img8,
      text1: "Vista Chrome",
      text2: "Heavy Duty Acosutic Rubber with integrated All-Damp-proofing",
      desc: "Click Image to See More",
    },
  ];
  const handleNavigate = (image) => {

    navigate(`/underlaysdetails/${image.text1}`, { state: { image } });
  };
  return (
    <>
      <div>
      {images.map((image, index) => (
        <Parallax
          key={index}
          bgImage={image.url}
          strength={300}
          
          bgImageStyle={{
            width: "100%",
            height: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className={`section section-${index + 1}`}
          onClick={() => handleNavigate(image)}
          key={index}
          style={{ cursor: "pointer" }}>
            <div className="text-overlay">
              <h2>{image.text1}</h2>
              <h4>{image.text2}</h4>
              <p >{image.desc}</p>
            </div>
            
          </div>
        </Parallax>
      ))}
    </div>
    </>
  );
};

export default Underlays;
