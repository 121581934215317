import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Laminate12MMDetail = () => {
  const location = useLocation();
  const { image } = location.state || {};
  const [currentImage, setCurrentImage] = useState(image.url || "");

  // Safely access image

  if (!image) {
    return (
      <p>
        Product data is not available. Please navigate from the Laminates page.
      </p>
    );
  }

  const { name, description, text } = image;

 const colorOptions = {
    "Solid": [
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d2562_noce_rubio.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d2597_dublin_oak.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d3522_glasgow_oak-1.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d3569_gunpowder_grey-1.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d4491_santiago_oak-1.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d4940_minto_pine.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d8013_helsinki_oak-1.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d8014_new_york_oak-2.jpg",
      },
    ],
    Effect: [
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2023/06/ALPINE.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2023/06/ALTAY.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2023/06/ATLAS.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2023/06/ELBRUZ.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2023/06/EVEREST.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2023/06/FUJI.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2023/06/LOGAN.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2023/06/NIRVANA.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/PAMIR.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/ROSSO.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/SOLARO.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/TIBET.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/TOROS.png",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2024/05/URAL.png",
      },
    ],
    "Solid Chrome Plank": [
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d2025_leysin_oak.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d3032_verbier_oak.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d3033_zermatt_oak.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d3034_engelberg_oak.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d3035_davos_oak.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d4202_interlaken_oak.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d7092_gstaad_oak.jpg",
      },
    ],
    "Xpressions Herringbone":[
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/Georgian-Oak-2.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/Tudor-Oak.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/Winchester-Oak.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/sand_oak.jpg",
      },
    ],
    "Krono Quatro": [
      {
        name: "Krakow",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/quatro/krakow/Quatro-Krakow-planks__FocusFillWzU2MCwzOTIsInkiLDBd.jpg",
      },
      {
        name: "Poznan",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/quatro/poznan/Quatro-Poznan-planks__FocusFillWzU2MCwzOTIsIngiLDdd.jpg",
      },
      {
        name: "Warsaw",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/quatro/warsaw/Quatro-Warsaw-planks__FocusFillWzU2MCwzOTIsInkiLDBd.jpg",
      },
      {
        name: "Gdansk",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/quatro/gdansk/3509_rzut_z_gory_3_panele_a4_big__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
    ],
    "Krono King Floor":[
      {
        name: "Celtic Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/kingfloor/new-product-details/celtic-oakD2594__FocusFillWzU2MCwzOTIsIngiLDI4N10.jpg",
      },
      {
        name: "Dublin Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/kingfloor/new-product-details/2597_rzut_z_gory_3_panele_a4_big__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Masala Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/kingfloor/new-product-details/2583_rzut_z_gory_3_panele_a4_big__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Murano Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/kingfloor/new-product-details/3501_rzut_z_gory_3_panele_a4_big_rgb__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Urban Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/king-floor/new-product-details/3338_rzut_z_gory_5_paneli_a4_big_rgb_net__FocusFillWzU2MCwzOTIsInkiLDg0XQ.jpg",
      },
      {
        name: "Capricorn Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/king-floor/new-product-details/4567_diy__FocusFillWzU2MCwzOTIsInkiLDg0XQ.jpg",
      },
      {
        name: "Livorno",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/kingfloor/new-product-details/3033_rzut_z_gory_3_panele_a4_big__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "New York Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/king-floor-2/new-york-oak/New-york-oak2__FocusFillWzU2MCwzOTIsIngiLDIzOV0.jpg",
      },
      {
        name: "Ferrara",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/kingfloor/new-product-details/3034_rzut_z_gory_3_panele_A4_big_RGB__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Scorpio",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/king-floor-2/new-product-details/4569_diy__FocusFillWzU2MCwzOTIsInkiLDg0XQ.jpg",
      },
      {
        name: "Leonardo",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/king-floor-2/leonardo/Leonardo-D3347__FocusFillWzU2MCwzOTIsInkiLDJd.jpg",
      },
      {
        name: "Augusta Walnut",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/king-floor-2/augusta-walnut/Agusta-Walnut-v3__FocusFillWzU2MCwzOTIsInkiLDE5OV0.jpg",
      },
      
    ],
    "Krono Sound":[
      {
        name: "Blues Chestnut",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/sound/new-product-details/3342_rzut_z_gory_3_panele_format_A4_big__FocusFillWzU2MCwzOTIsInkiLDJd.jpg",
      },
      {
        name: "Chillout Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/sound/new-product-details/3346_rzut_z_gory_3_panele_format_A4_big__FocusFillWzU2MCwzOTIsInkiLDJd.jpg",
      },
      {
        name: "Fado Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/sound/new-product-details/3885_rzut_z_gory_3_panele_A4_big__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Opera Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/sound/new-product-details/3884_rzut_z_gory_3_panele_A4_big__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Piano Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/sound/new-product-details/3883_rzut_z_gory_3_panele_A4_big__FocusFillWzU2MCwzOTIsInkiLDFd.jpg",
      },
      {
        name: "Soul Oak",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/kronopol/sound/new-product-details/3332_rzut_z_gory_3_panele_format_A4_big__FocusFillWzU2MCwzOTIsInkiLDJd.jpg",
      },
    ], 
    "Xtreme Long Plank": [
      {
        name: "Belgian Oak Beige",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/x-treme/x-treme/belgian-oak-beige/4__FocusFillWzU2MCwzOTIsIngiLDE2MV0.jpg",
      },
      {
        name: "Belgian Oak Brown",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/x-treme/x-treme/belgian-oak-brown/7__FocusFillWzU2MCwzOTIsIngiLDE2MV0.jpg",
      },
      {
        name: "Castle Oak Original",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/x-treme/x-treme/castle-oak-original/6__FocusFillWzU2MCwzOTIsIngiLDE2MV0.jpg",
      },
    ],
  };

  const filteredColorOptions = colorOptions[text] || [];

  const handleColorClick = (color) => {
    setCurrentImage(color.imageUrl);
  };

  return (
    <div className="product-detail">
      <Link to="/8mm_Laminate" className="back-link">
        Back
      </Link>
      <div className="product-main">
        <img className="main-product-image" src={currentImage} alt={name} />
        <div className="product-info">
          <h2>{name}</h2>
          <strong>{description}</strong>
          <h5>Category: {text}</h5>

          <div className="colors">
            {filteredColorOptions.length > 0 ? (
              filteredColorOptions.map((color, idx) => (
                <div
                  key={idx}
                  className="color-swatch"
                  onClick={() => handleColorClick(color)}
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    margin: "2px",
                  }}
                >
                  <img
                    src={color.imageUrl}
                    alt={`Color Option ${idx + 1}`}
                    style={{
                      width: "70px",
                      height: "70px",
                      border: "2px solid #ddd",
                    }}
                  />
                  <h6>{color.name}</h6>
                </div>
              ))
            ) : (
              <p>No colors available for this category.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Laminate12MMDetail;
