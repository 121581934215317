import React, { useState }  from "react";
import "../../styles/WallTiles.css";

import img1 from "../../images/waterproofspc/Grizly_5.5mm.jpg";
import { Link } from "react-router-dom";
// import img2 from "../../images/laminates/8mm/img2.jpg";
// import img3 from "../../images/laminates/8mm/img3.jpg";
// import img4 from "../../images/laminates/8mm/img4.jpg";
// import img5 from "../../images/laminates/8mm/img5.jpg";
const Laminates = () => {
        const productData = {
          names: ["BUG", "BUZZ", "FLY", "SWAG"],
          
          categories: ["Grizzly 5.5mm", "Grizzly 5.5mm", "Grizzly 5.5mm", "Grizzly 5.5mm"],
          // text: [
          //   "SPC Flooring",
          //   "Durable Laminate",
          //   "Luxury Vinyl Plank",
          //   "Hybrid Flooring",
          //   "Engineered Wood Plank"
          // ]
        };
      
        // Array of 5 color options
        const colorOptions = [
          "https://www.bearfoot.ie/assets/waterproof-spc/5-5mm/republic-grizzly/bug/Bug_001__FocusFillWzU2MCwzOTIsInkiLDg0XQ.jpg",
          "https://www.bearfoot.ie/assets/waterproof-spc/5-5mm/republic-grizzly/buzz/Buzz_001__FocusFillWzU2MCwzOTIsInkiLDg0XQ.jpg",
          "https://www.bearfoot.ie/assets/waterproof-spc/5-5mm/republic-grizzly/fly/Fly_001__FocusFillWzU2MCwzOTIsInkiLDg0XQ.jpg",
          "https://www.bearfoot.ie/assets/waterproof-spc/5-5mm/republic-grizzly/swag/Swag_001__FocusFillWzU2MCwzOTIsInkiLDg0XQ.jpg",
         
        ];
      
        const [currentImage, setCurrentImage] = useState(colorOptions[0]);
        const [currentIndex, setCurrentIndex] = useState(0);
      
        const handleColorClick = (colorImage, idx) => {
          setCurrentImage(colorImage);
          setCurrentIndex(idx);
        };

  return (
    <>
      <div className="tile1">
        <div className="text">
          <div className="first">
            <p className="second">
              <h1>Grizzly 5.5mm</h1>
              Waterproof SPC (Stone Plastic Composite) flooring is a game-changer for moisture-prone 
              areas, as it is 100% waterproof and impervious to water damage. Its rigid core construction
              provides exceptional durability and stability, resisting warping, cracking, and buckling even 
              in extreme conditions. SPC flooring combines the beauty of wood-like designs with unparalleled 
              practicality, making it suitable for bathrooms, kitchens, and basements. Easy to install with 
              click-and-lock systems, it also requires minimal maintenance, saving time and effort in cleaning. 
              Moreover, it is highly scratch-resistant and pet-friendly, offering a long-lasting and cost-effective
              flooring solution for households and commercial spaces alike.
            </p>
           
           
           <div className="img-link">           
             <Link to='/SPC8mm'>
              <img src={img1} alt="Laminate Tile 1" className="main_image" />
              {/* <div className="img-info">
                  <p>Click Image to see More</p>
               </div> */}
            </Link>
            </div>
          
          </div>
        </div>
       
      </div>
      <div className="product-detail">
                  {/* <Link to="/8mm_wps" className="back-link">
                    Back
                  </Link> */}
                  <div className="product-main">
                    <img
                      className="main-product-image"
                      src={currentImage}
                      alt={productData.names[currentIndex]}
                      style={{
                        width: "300px",
                        height: "300px",
                        border: "1px solid #ddd",
                        marginBottom: "10px",
                      }}
                    />
                    <div className="product-info">
                      <h2>{productData.names[currentIndex]}</h2>
                      {/* <strong>{productData.descriptions[currentIndex]}</strong> */}
                      <h5>Category: {productData.categories[currentIndex]}</h5>
                      {/* <p>{productData.text[currentIndex]}</p> */}
            
                      <div className="colors">
                        {colorOptions.map((colorImage, idx) => (
                          <div
                            key={idx}
                            className="color-swatch"
                            onClick={() => handleColorClick(colorImage, idx)}
                            style={{
                              cursor: "pointer",
                              display: "inline-block",
                              margin: "2px",
                            }}
                          >
                            <img
                              src={colorImage}
                              alt={`Color Option ${idx + 1}`}
                              style={{
                                width: "70px",
                                height: "70px",
                                border: currentIndex === idx ? "2px solid blue" : "2px solid #ddd",
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
          </div>
      {/* <div className="container">
        <div className="tile">
          <div className="image">
            <img src={img2} alt="Laminate Tile 2" />
          </div>
        </div>

        <div className="tile">
          <div className="image">
            <img src={img3} alt="Laminate Tile 3" />
          </div>
        </div>

        <div className="tile">
          <div className="image">
            <img src={img4} alt="Laminate Tile 4" />
          </div>
        </div>

        <div className="tile">
          <div className="image">
            <img src={img5} alt="Laminate Tile 5" />
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Laminates;
