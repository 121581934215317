import React from "react";
import "../../styles/WallTiles.css";
import { Parallax } from "react-parallax";
import img1 from "../../images/floortiles/Bathroom/image.png";
import img2 from "../../images/floortiles/Bathroom/image2.png";
import img3 from "../../images/floortiles/Bathroom/image3.png";
import img4 from "../../images/floortiles/Bathroom/image4.png";

import img5 from "../../images/floortiles/bedroom/image.png";
import img6 from "../../images/floortiles/bedroom/image2.png";
import img7 from "../../images/floortiles/bedroom/image3.png";
import img8 from "../../images/floortiles/bedroom/image4.png";

import img9 from "../../images/floortiles/kitchen/image.png";
import img10 from "../../images/floortiles/kitchen/image2.png";
import img11 from "../../images/floortiles/kitchen/image3.png";
import img12 from "../../images/floortiles/kitchen/image4.png";

import img13 from "../../images/floortiles/LivingRoom/image.png";
import img14 from "../../images/floortiles/LivingRoom/image2.png";
import img15 from "../../images/floortiles/LivingRoom/image3.png";
import img16 from "../../images/floortiles/LivingRoom/image4.png";

const sections = [
  {
    title: "Bathroom Floor Tiles",
    description: `CFL’s presents the new extravagant range of bathroom tiles that come
      with astounding features. The innovative collection of matt finish tiles
      consists of a variety of spectacular designs which will surely provide a sense
      of wideness to the area with a dash of sophistication & style. As matt surfaces
      offer better grip and resistance, such matt finish tiles are the evident choice
      for flooring as well as wall tiling, especially in bathrooms.`,
    mainImage: img1,
    parallaxImages: [
      { url: img2, text: "Bathroom Floor Tiles" },
      { url: img3, text: "Bathroom Floor Tiles" },
      { url: img4, text: "Bathroom Floor Tiles" },
    ],
  },
  {
    title: "Bedroom Floor Tiles",
    description: `Revamp your house with an eye-catching range of bedroom floor tiles
      by CFL. Manufactured with the help of advanced digital machinery, the assortment
      of floor tiles comes in various textures, and dimensions to match the interior
      of your house. The polished tiles by the brand have a reflective surface that
      effortlessly brighten up the whole area. The radiance of the floor tiles is
      durable and easy to keep up. To add that earthy and rustic look of natural wood
      to your bedroom or living room, choose from our eclectic collection of wooden
      floor tiles.`,
    mainImage: img5,
    parallaxImages: [
      { url: img6, text: "Bedroom Floor Tiles" },
      { url: img7, text: "Bedroom Floor Tiles" },
      { url: img8, text: "Bedroom Floor Tiles" },
    ],
  },
  {
    title: "Kitchen Floor Tiles",
    description: `CFL’s grand range of exquisite kitchen floor tiles are a feast for the eyes.
      The ultimate collection has the ability to transform any plain Jane dimension to an
      ultra-gorgeous one. The designer tiles are made using state-of-the art technology to
      ensure that each kitchen floor tile is of high quality. Our tiles are a perfect blend
      of mind-blowing designs with appealing colors and extraordinary finishes. The large
      collection is made to cater to the individual styles and needs of our customers.`,
    mainImage: img9,
    parallaxImages: [
      { url: img10, text: "Kitchen Floor Tiles" },
      { url: img11, text: "Kitchen Floor Tiles" },
      { url: img12, text: "Kitchen Floor Tiles" },
    ],
  },
  {
    title: "Living Room Floor Tiles",
    description: `CFL's exclusive ceramic and vitrified floor tiles elevate the beauty of your
      living room with a blend of durability and elegance. Available in various colors,
      textures, and sizes, these designer tiles cater to every style preference, from
      classic to modern. Crafted with cutting-edge technology, they redefine aesthetics
      and add sophistication to your space.`,
    mainImage: img13,
    parallaxImages: [
      { url: img14, text: "Living Room Floor Tiles" },
      { url: img15, text: "Living Room Floor Tiles" },
      { url: img16, text: "Living Room Floor Tiles" },
    ],
  },
];

const IndoorFloorTiles = () => {
  return (
    <div>
      {sections.map((section, index) => (
        <div key={index}>
          <div className="tile1">
            <div className="text">
              <div className="first">
                <p className="second">
                  <h1>{section.title}</h1>
                  {section.description}
                </p>
                <div>
                  <img
                    src={section.mainImage}
                    alt={`${section.title} Main`}
                    className="main_image"
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            {section.parallaxImages.map((image, idx) => (
              <Parallax
                key={idx}
                bgImage={image.url}
                strength={300}
                bgImageStyle={{
                  width: "100%",
                  height: "100%",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className={`section section-${idx + 1}`}>
                  <div className="text-overlay">
                    <h2>{image.text}</h2>
                  </div>
                </div>
              </Parallax>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default IndoorFloorTiles;
