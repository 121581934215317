import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const HardwoodHeeringboneDetail = () => {
  const location = useLocation();
  const { image } = location.state || {};

  console.log(image?.text, "Category (text)");

  const [currentImage, setCurrentImage] = useState("");

  // Set initial image if available
  useEffect(() => {
    if (image?.url) {
      setCurrentImage(image.url);
    }
  }, [image]);

  // Handle the case when image is not available
  if (!image) {
    return (
      <div className="error-message">
        <p>
          Product data is not available. Please navigate from the Hardwoods
          page.
        </p>
        <Link to="/hardwoods_herringbone" className="back-link">
          Back to Products
        </Link>
      </div>
    );
  }

  const { name, description, text } = image;

  // Define color options
  const colorOptions = {
    "Hakwood Herringbone": [
      {
        name: "Charisma",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/hakwood/herringbone/charisma/Hakwood-Lite-Charisma-herringbone__FocusFillWzU2MCwzOTIsInkiLDg0XQ.jpg",
      },
      {
        name: "Vital",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/hakwood/herringbone/vital/Hakwood-Lite-Vital-herringbone__FocusFillWzU2MCwzOTIsInkiLDg0XQ.jpg",
      },
      {
        name: "Legacy",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/hakwood/herringbone/legacy/Hakwood-Lite-Legacy-herringbone__FocusFillWzU2MCwzOTIsInkiLDg0XQ.jpg",
      },
      {
        name: "Grace",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/hakwood/herringbone/grace/Hakwood-Lite-Grace-herringbone__FocusFillWzU2MCwzOTIsInkiLDg0XQ.jpg",
      },
    ],
    "Vista Herringbone": [
      {
        name: "SALSA HERRINGBONE",
        imageUrl:
          "https://www.bearfoot.ie/assets/hardwoods/herringbone/vista-herringbone/salsa-herringbone/Viata-Orig-SalsaHerring1__FocusFillWzU2MCwzOTIsIngiLDE0XQ.jpg",
      },
      {
        name: "CAJUN HERRINGBONE",
        imageUrl:
          "https://www.bearfoot.ie/assets/hardwoods/herringbone/vista-herringbone/cajun-herringbone/Cajun-H__FocusFillWzU2MCwzOTIsInkiLDE0XQ.bone.jpg",
      },
      {
        name: "NUTMEG HERRINGBONE",
        imageUrl:
          "https://www.bearfoot.ie/assets/hardwoods/herringbone/vista-herringbone/nutmeg-herringbone/Nutmeg-H__FocusFillWzU2MCwzOTIsInkiLDQ2XQ.bone.jpg",
      },
      {
        name: "COCOA HERRINGBONE",
        imageUrl:
          "https://www.bearfoot.ie/assets/hardwoods/herringbone/vista-herringbone/cocoa-heringbone/Cocoa-H__FocusFillWzU2MCwzOTIsInkiLDM3XQ.bone.jpg",
      },
      {
        name: "SAGE HERRINGBONE",
        imageUrl:
          "https://www.bearfoot.ie/assets/hardwoods/herringbone/vista-herringbone/sage-herringbone/Sage-H__FocusFillWzU2MCwzOTIsInkiLDE0XQ.bone.jpg",
      },
    ],
    "Intasa Herringbone": [
      {
        name: "Invisible",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/intasa/herringbone/invisible/INVISIBLE-Herringbone-2__FocusFillWzU2MCwzOTIsIngiLDE0NF0.jpg",
      },
      {
        name: "Whiskey",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/intasa/herringbone/whiskey/WHISKEY-Herringbone-1__FocusFillWzU2MCwzOTIsIngiLDE0XQ.jpg",
      },
      {
        name: "Barrell",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/intasa/herringbone/barrell/BARRELL-Herriongbone2__FocusFillWzU2MCwzOTIsIngiLDE0NF0.jpg",
      },
    ],
    "Esco Herringbone": [
      {
        name: "Gothic",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/esco/herringbone/gothic/gothic-hb2-3-yes__FocusFillWzU2MCwzOTIsIngiLDYxXQ.jpg",
      },
      {
        name: "Smoked Basecoat",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/esco/herringbone/smoked-basecoat/smoked-bascoat-__FocusFillWzU2MCwzOTIsIngiLDE4XQ.jpg",
      },
      {
        name: "Natural White",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/esco/herringbone/natural-white/Esco-Natural-White-Board-v2__FocusFillWzU2MCwzOTIsIngiLDE0XQ.jpg",
      },
      {
        name: "Washy Grey",
        imageUrl:
          "https://www.bearfoot.ie/assets/floors/esco/herringbone/washy-grey/washy-grey-hb4-yes__FocusFillWzU2MCwzOTIsIngiLDE0XQ.jpg",
      },
      {
        name: "Smoked Natural",
        imageUrl:
          "https://www.bearfoot.ie/assets/hardwoods/herringbone/esco-herringbone/new-product-details/SmokedNatPlank__FocusFillWzU2MCwzOTIsIngiLDE2XQ.png",
      },
    ],

   "Vista Ultra Herringbone": [
  { 
    "name": "Sea Fog Herringbone", 
    "imageUrl": "https://www.bearfoot.ie/assets/hardwoods/herringbone/vista-ultra-herringbone/sea-fog-herringbone/Sea-Fog-H-Bone_001__FocusFillWzU2MCwzOTIsIngiLDEzXQ.jpg" 
  },
  { 
    "name": "Blackwing Herringbone", 
    "imageUrl": "https://www.bearfoot.ie/assets/hardwoods/herringbone/vista-ultra-herringbone/blackwing-herringbone/Blackwing-H-Bone_001__FocusFillWzU2MCwzOTIsIngiLDEzXQ.jpg" 
  },
  { 
    "name": "Pyramid Herringbone", 
    "imageUrl": "https://www.bearfoot.ie/assets/hardwoods/herringbone/vista-ultra-herringbone/pyramid-herringbone/Pyramind-H-Bone_001__FocusFillWzU2MCwzOTIsIngiLDEzXQ.jpg" 
  }
]
,
    "Esco 190mm Plank": [
      { name: "Chestnut", imageUrl: "https://example.com/images/chestnut.jpg" },
      { name: "Walnut", imageUrl: "https://example.com/images/walnut.jpg" },
    ],
    "Esco 250mm Plank": [
      { name: "Chestnut", imageUrl: "https://example.com/images/chestnut.jpg" },
      { name: "Walnut", imageUrl: "https://example.com/images/walnut.jpg" },
    ],
  };

  const filteredColorOptions = text ? colorOptions[text] || [] : [];

  console.log("Filtered Colors:", filteredColorOptions);

  const handleColorClick = (color) => {
    setCurrentImage(color.imageUrl);
  };

  return (
    <div className="product-detail">
      <Link to="/hardwoods_herringbone" className="back-link">
        Back
      </Link>
      <div className="product-main">
        <img
          className="main-product-image"
          src={currentImage || "https://example.com/default-image.jpg"} // Fallback image
          alt={`Preview of ${name}`}
        />
        <div className="product-info">
          <h2>{name}</h2>
          <strong>{description}</strong>
          {/* <h5>Category: {text}</h5> */}
          <div className="colors">
            {filteredColorOptions.length > 0 ? (
              filteredColorOptions.map((color, idx) => (
                <div
                  key={idx}
                  className="color-swatch"
                  onClick={() => handleColorClick(color)}
                  
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    margin: "2px",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={color.imageUrl}
                    alt={`Color swatch of ${color.name}`}
                    style={{
                      width: "70px",
                      height: "70px",
                      border: "2px solid #ddd",
                      borderRadius: "5px",
                    }}
                  />
                  <h6>{color.name}</h6>
                </div>
              ))
            ) : (
              <p>No colors available for this category.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HardwoodHeeringboneDetail;
