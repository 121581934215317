import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Laminate14MMDetail = () => {
  const location = useLocation();
  const { image } = location.state || {};
  const [currentImage, setCurrentImage] = useState(image.url || "");

  // Safely access image

  if (!image) {
    return (
      <p>
        Product data is not available. Please navigate from the Laminates page.
      </p>
    );
  }

  const { name, description, text } = image;

 const colorOptions = {
    "Authentic": [
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d6101_fine.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d6102_bright.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d6103_raw.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d6104_smoky.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d6105_sunny.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d6106_natural.jpg",
      },
    ],
    Evolution: [
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d4508_ivory_oak.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d4510_graphite_oak.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d4511_pearl_oak.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d4512_copper_oak.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d4513_sandstone_oak.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d4515_gold_oak.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d4516_bronze_oak.jpg",
      },
    ],
    "Origin": [
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d4492_beach.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d4493_sunset.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d4494_snow.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d4495_sunshine.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d4496_rock.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d4498_moon.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d7093_forest.jpg",
      },
      {
        imageUrl:
          "https://signaturefloors.ie/wp-content/uploads/2021/05/d7094_dune.jpg",
      },
    ],
  };

  const filteredColorOptions = colorOptions[text] || [];

  const handleColorClick = (color) => {
    setCurrentImage(color.imageUrl);
  };

  return (
    <div className="product-detail">
      <Link to="/8mm_Laminate" className="back-link">
        Back
      </Link>
      <div className="product-main">
        <img className="main-product-image" src={currentImage} alt={name} />
        <div className="product-info">
          <h2>{name}</h2>
          <strong>{description}</strong>
          <h5>Category: {text}</h5>

          <div className="colors">
            {filteredColorOptions.length > 0 ? (
              filteredColorOptions.map((color, idx) => (
                <div
                  key={idx}
                  className="color-swatch"
                  onClick={() => handleColorClick(color)}
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    margin: "2px",
                  }}
                >
                  <img
                    src={color.imageUrl}
                    alt={`Color Option ${idx + 1}`}
                    style={{
                      width: "70px",
                      height: "70px",
                      border: "2px solid #ddd",
                    }}
                  />
                  <h6>{color.name}</h6>
                </div>
              ))
            ) : (
              <p>No colors available for this category.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Laminate14MMDetail;
